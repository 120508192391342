import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { getAsset } from "../../functions/getAsset";
import { navigateToScreen } from "../../navigationResponsive";
import TutorialContainer from "./TutorialContainer";

  const Step2TextComponent = ({ 
    userSettingsState,
    showOnlyFirst = false,
    navigation,
    isFromHome = false,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <TutorialContainer
    isSetVideoSlider={true}
    isFromHome={isFromHome}
    isOpposite={true}
    userSettingsState={userSettingsState}
    videoUri={getAsset(require('../../../assets/tutorial/step2.mp4'))}
    durationTime={"00:00:48.0000000"}
    title1={i18n.t('tutorial.step2_title1')}
    title2={i18n.t('tutorial.step2_title2')}
    showButton={showOnlyFirst}
    onPress={() => {
      navigateToScreen(navigation, 'StoreFlow', `TutorialExplanation`, { Type: "Step2" }, "Step2");
    }}
    text={
    <View>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text1')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text2')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text3')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text4')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text5')}</Text>
    {
        showOnlyFirst ? 
        <Text style={styles.text}>...</Text>
        : <>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text6')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text7')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text8')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text9')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text10')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.step2_text11')}</Text>
        </>
    }
    </View>
    }
    />
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  text: {
    fontSize: responsiveStyles.fontSize_H5,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    maxWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
    ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.48,
    marginHorizontal: 20,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
});

export default Step2TextComponent;