import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { getPaddingScreen } from "../../functions/ScreenDimensionsLogic";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const BottomCardComponent = ({ userSettingsState, displayType, setDisplayType, setInputsByUser }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const paddingScreen = getPaddingScreen(responsiveStyles.screenWidth);
  const styles = styling(responsiveStyles, paddingScreen);
  return (
    <View style={styles.bottomCardContainer}>
      {/* <ButtonTypeButton
      userSettingsState={userSettingsState}
        style={[styles.cardContainer, styles.cardBorderContainer, displayType === 'Posts' ? styles.onfocus : null, { backgroundColor:'blue' }]}
        onPress={() => {
          console.log();
          setDisplayType('Posts')
        }}
        >
        {i18n.t('account.MyAccount_BottomCard_Posts')}
    </ButtonTypeButton> */}
    <TouchableOpacity 
    style={[styles.cardContainer, displayType === 'Posts' ? styles.onfocus : null]}
    onPress={() => {
      console.log();
      setDisplayType('Posts')
    }}
    >
    <Text style={[styles.text, displayType === 'Posts' ? styles.onfocusText : null ]}>{i18n.t('account.MyAccount_BottomCard_Posts')}</Text>
    </TouchableOpacity>

    <TouchableOpacity 
    style={[styles.cardContainer, styles.cardBorderContainer, displayType === 'Profiles' ? styles.onfocus : null]}
    onPress={() => setDisplayType('Profiles')}
    >
    <Text style={[styles.text, displayType === 'Profiles' ? styles.onfocusText : null]}>{i18n.t('account.MyAccount_BottomCard_Profiles')}</Text>
    </TouchableOpacity>

    <TouchableOpacity 
    style={[styles.cardContainer, styles.cardBorderContainer, displayType === 'Jewlery' ? styles.onfocus : null]}
    onPress={() => setDisplayType('Jewlery')}
    >
    <Text style={[styles.text, displayType === 'Jewlery' ? styles.onfocusText : null]}>{i18n.t('account.MyAccount_BottomCard_Jewlery')}</Text>
    </TouchableOpacity>

    <TouchableOpacity 
    style={[styles.cardContainer, styles.cardBorderContainer, displayType === 'Account' ? styles.onfocus : null]}
    onPress={() => {
      if(displayType !== 'Account'){
        setInputsByUser();
      }
      setDisplayType('Account');
    }}
    >
    <Text style={[styles.text, displayType === 'Account' ? styles.onfocusText : null]}>{i18n.t('account.MyAccount_BottomCard_Account')}</Text>
    </TouchableOpacity>

  </View>

  );
}
const styling = (responsiveStyles, paddingScreen) => StyleSheet.create({



  bottomCardContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-evenly',
    alignItems:'flex-start',
    marginTop: !responsiveStyles.isMobile ? 40 : 20,
    marginBottom: !responsiveStyles.isMobile ? 20 : null,
    paddingHorizontal: paddingScreen,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: !responsiveStyles.isMobile ? responsiveStyles.fontSize_H4 : responsiveStyles.fontSize_H5,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: GlobalStyles.fonts.application_font,
  },
  onfocus:{


  },
  onfocusText: {
    color: GlobalStyles.colors.selectedItemColor,
    fontWeight: '700',
    // minWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth / 20 : responsiveStyles.screenWidth / 6,
    // maxWidth: !responsiveStyles.isMobile ? responsiveStyles.screenWidth / 20 : responsiveStyles.screenWidth / 6,
    borderBottomColor: GlobalStyles.colors.selectedItemColor,
    borderBottomWidth: 2,
    paddingBottom: 10,
  },
  cardContainer: {
    flex:1,
  },
  cardBorderContainer: {
    borderRightWidth: responsiveStyles.isRTL ? 3 : null,
    borderLeftWidth: !responsiveStyles.isRTL ? 3 : null,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.selectedItemBackgroundColor,
  },
});

export default BottomCardComponent;