import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, FlatList, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { AntDesign } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import TextInputWithErrorComponent from "../forms/TextInputWithErrorComponent";
import { GenericAlert } from "../alert/GenericAlert";
import CartEmptyListComponent from "../displayComponent/CartEmptyListComponent";
import QuantityStoreItemComponent from "./QuantityStoreItemComponent";
import { Feather } from '@expo/vector-icons';
import { Context as ShopContext } from '../../context/ShopContext';
import { navigateToScreen } from "../../navigationResponsive";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}


  const ShoppingCartComponent = ({ 
    userSettingsState, 
    data = [],
    navigation,

  }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
    const {state: shopState, addOrUpdateItemToCart, deleteItemFromCart } = useContext(ShopContext);
    const [discountCode, setDiscountCode] = useState(false);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const onPressDiscount = () => {
      setbodymessge(`Discount code "${discountCode}" does not exist!`);
      setGenericAlertVisible(true);
    }
    
  return (
    <View style={styles.discountContainer}>
      <FlatList
      scrollEnabled={false}
      data={data}
      renderItem={({ item, index }) =>
      {

        {console.log(`item is ${JSON.stringify(item)}`);}
        const isWithDiscount = item.item.Discount > 0;
    const realPrice = item.item.Price;
    const afterDiscount = isWithDiscount ? (item.item.Price * (100 - item.item.Discount) / 100).toFixed(2) : realPrice;
    console.log(`isWithDiscount: ${isWithDiscount} afterDiscount: ${afterDiscount}`);

     return <View style={styles.itemContainer}>
      <View style={styles.itemImageContainer}>
        <TouchableOpacity
        onPress={() => {
          navigateToScreen(navigation, 'StoreFlow', `ItemDetails`, { Id: item.item.Id }, item.item.Id);
          // navigate('StoreFlow', { screen: 'ItemDetailsFromCart', params: { Id: item.item.Id } });
          // navigate("ItemDetailsFromCart", { Id: item.item.Id });
        }}
        >
            <FastImage
            source={{ uri: item.item.Images?.length > 0 ? item.item.Images[0] : null }}
            resizeMode='cover'
            style={styles.image}
            />
            </TouchableOpacity>
        </View>
        <View style={styles.itemInfoContainer}>
          <View style={styles.itemNameAndRemoveItemContainer}>
          <TouchableOpacity 
          style={{flex:1}}
          onPress={() => {
            navigateToScreen(navigation, 'StoreFlow', `ItemDetails`, { Id: item.item.Id }, item.item.Id);
            // navigate('StoreFlow', { screen: 'ItemDetailsFromCart', params: { Id: item.item.Id } });
            // navigate("ItemDetailsFromCart", { Id: item.item.Id });
          }}
          >
        <Text numberOfLines={2} style={[styles.text, styles.itemTitle]}>{responsiveStyles.Language === "he-IL" ? item.item.Name_he : item.item.Name_en}</Text>
        </TouchableOpacity>
        <TouchableOpacity 
        style={styles.trashContainer}
        onPress={() => {
          deleteItemFromCart(item.itemId);
        }}
        >
          <Feather name="trash-2" size={20} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
          </TouchableOpacity>
          </View>

          {item.item.frontSideText ? 
          <View style={styles.engravingContainer}>
          <Text style={styles.text}>{i18n.t('store.ItemDetailsScreen_EngravingTextContainer_frontSideText_label')}: </Text>
          <Text style={styles.text}>{item.item.frontSideText}</Text>
          </View>
          : null }

          {item.item.backSideText ? 
          <View style={styles.engravingContainer}>
          <Text style={styles.text}>{i18n.t('store.ItemDetailsScreen_EngravingTextContainer_backSideText_label')}: </Text>
          <Text style={styles.text}>{item.item.backSideText}</Text>
          </View>
          : null }

        <View style={styles.quantityAndPriceContainer}>
          <View style={styles.quantityContainer}>
        <QuantityStoreItemComponent
        userSettingsState={userSettingsState} 
        quantity={item.quantity}
        canDelete={true}
        setQuantity={(newQuantity) => {
          console.log(`newQuantity is ${newQuantity}`);
          addOrUpdateItemToCart(item.item, item.itemId, newQuantity - item.quantity);
        }}
        styleContainer={{alignItems:'center'}}
        />
        </View>
         <View style={styles.priceContainer}>
          <Text style={[styles.text, styles.itemTitle, styles.priceText,isWithDiscount ? styles.itemBeforeDiscount : { fontWeight: 'normal' }]}>{realPrice} ₪</Text>
          { isWithDiscount ? 
    <Text style={[styles.text, styles.itemTitle, styles.priceText, styles.itemAfterDiscount]}>{afterDiscount} ₪</Text>
    : null }
          </View>
        </View>
        
        </View>
     </View>
      }}
      
      ListEmptyComponent={
      <CartEmptyListComponent userSettingsState={userSettingsState} />
        }

      />
    </View>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
 itemContainer: {
    // padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    paddingVertical: 10,
    marginRight: !responsiveStyles.isMobile  ? 20  : null,
    marginVertical: 10,
  },
  engravingContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
  },
  itemImageContainer: {
    //   backgroundColor: 'blue',
    // justifyContent:'flex-end',
    // alignItems:'flex-end',
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    // flex:1,
  },
  image: {
    // minWidth: 100,
    // maxWidth: 300,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.07 : responsiveStyles.screenWidth * 0.2,
    aspectRatio: 1 / 1,
    marginRight: !responsiveStyles.isRTL ? 10 : null,
    marginLeft: responsiveStyles.isRTL ? 10 : null,
    borderRadius: 15,
  },
   
  itemInfoContainer: {
    flexDirection:'column',
    justifyContent:'space-evenly',
    // flex:3,
    flex:1,
    
  },
  itemNameAndRemoveItemContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    flex:1,
    // backgroundColor:'blue',
    justifyContent: 'space-between'
  },
  
  text: {
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
},
itemTitle: {
  fontSize: responsiveStyles.fontSize_H5,
  fontFamily: GlobalStyles.fonts.application_font,
  fontWeight: '900',
  textAlign: responsiveStyles.isRTL ?  'right' : 'left',
},
priceText: {
  fontSize: responsiveStyles.fontSize_H5,
  fontFamily: GlobalStyles.fonts.application_font,
  fontWeight: 'bold',
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  
},
quantityAndPriceContainer: {

flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
flex:1,
alignItems:'center',
},
quantityContainer: {
flex:1,

},
priceContainer: {
  flex:1,
  alignItems: responsiveStyles.isRTL ? 'flex-start' : 'flex-end',
},

trashContainer: {
  justifyContent: 'flex-end', 
  flexDirection: 'row', 
  alignItems:'flex-end', 
  marginLeft: !responsiveStyles.isRTL ? -10 : null,
  marginRight: responsiveStyles.isRTL ? -10 : null,
  marginBottom: 10,
},
itemBeforeDiscount: {
  textDecorationLine: 'line-through',
  fontSize: responsiveStyles.fontSize_H4,
  fontFamily: GlobalStyles.fonts.application_font,

},
itemAfterDiscount: {
  color: GlobalStyles.colors.priceAfterDiscountColor,
  fontSize: responsiveStyles.fontSize_H4,
  fontFamily: GlobalStyles.fonts.application_font,
  fontWeight: 'bold',
  paddingTop: 5,
},
});

export default ShoppingCartComponent;