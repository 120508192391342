import { Dimensions } from "react-native";
import { getFontSize_H5 } from "../functions/ScreenDimensionsLogic";

export const ApplicationColor_lightBlue = '#92d7f6'; // most used in Store
export const ApplicationColor_Blue = '#519fd6';

export const Assistant_ExtraLight = "Assistant-ExtraLight";
export const Assistant_Light = "Assistant-Light";
export const Assistant_Regular = "Assistant-Regular";
export const Assistant_SemiBold = "Assistant-SemiBold";
export const Assistant_Bold = "Assistant-Bold";
export const Assistant_ExtraBold = "Assistant-ExtraBold";

export const Gold = '#c8ba79';
export const Lavender = '#ba93aa';


export const GlobalStyles = {
  fonts: {
    application_font: Assistant_Regular,
  },
  store: {
    iconColor: "#cda84c",
  },
    colors: {
      Gold: Gold,
      Lavender: Lavender,
      buttonBorderColor: "#353946",

      UpdateButton: Lavender,
      AddButton:Gold,
      CancelButton: "#a98195",

      // UpdateButton: "#32CD32",
      // AddButton:"#1E90FF",
      // CancelButton: "#FF0000",


      selectedItemColor: Gold,
      // selectedItemColor: ApplicationColor_lightBlue,
      Red: "red",
      ImageMediaModalButtonColor: 'gray',
      TitleBorderBottomColor: '#87959f',
      LightBlue: ApplicationColor_lightBlue,
      priceAfterDiscountColor: Lavender,
    },
    errorMessage:{
      fontSize: getFontSize_H5(Dimensions.get('window').width),
      color: 'red',
      marginTop: 2,
      fontFamily: Assistant_Regular,
    },
    dark:{

      // old
      // primaryBackgroundColor: "#131b21",
      // SecondrayBackgroundColor: "#203233",

      // new
      primaryBackgroundColor: "#24272f",
      SecondrayBackgroundColor: "#1b1e24",
      textColor: 'white',
      placeholderTextColor: 'white',
      textColorOpposite: 'black',
      textColorModal: 'white',
      textValueColor: "#636363",
      inputColor: 'white',
      iconColor: 'white',
      iconColorOpposite: 'black',
      inputBackgroundColor: Gold, //

      inputBorderColor: "#87959f", //
      inputBorderColorOnFocus: Lavender,
      inputHeaderTextColor: 'white', // 
      inputHeaderTextColorFocus: 'white', //
      iconMediaColor: "white",
      alertButtonColor: '#007bff',
      placeholderColor: 'gray',

      selectedItemBackgroundColor: '#353945',


    },
    light:{
      primaryBackgroundColor: "#f5f5f5",
      // primaryBackgroundColor: "white", // old
      SecondrayBackgroundColor: "white",
      textColor: 'black',
      placeholderTextColor: 'black',
      textColorOpposite: 'white',
      textColorModal: 'white',
      textValueColor: "#636363",
      inputColor: 'black',
      iconColor: 'black',
      iconColorOpposite: 'white',
      inputBackgroundColor: Gold,

      inputBorderColor: "#87959f", //
      inputBorderColorOnFocus: Lavender,
      inputHeaderTextColor: 'black',
      inputHeaderTextColorFocus: '#3f51b5de',
      iconMediaColor: "white",
      placeholderColor: 'gray',

      selectedItemBackgroundColor: '#ebecf0',

    },
    videoSlider: {
      minimumTrackTintColor: "#00a785",
      maximumTrackTintColor:"#cecece",
      alertButtonColor: '#007bff',

      
    },
  };