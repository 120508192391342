import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Text, BackHandler, Platform, useWindowDimensions, TouchableOpacity } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import ButtonTypeText from '../../components/inputs/ButtonTypeText';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import { navigateToScreen } from '../../navigationResponsive';
import { GetOrderPaymentSuccessfulById } from '../../api/apiCalls/ProfileCalls';
import { getNavigationParams } from '../../functions/navigationLogic';
import ErrorComponent from '../../components/ErrorComponent';
import LoadingScreen from '../../components/LoadingScreen';
import { formatDate } from '../../functions/formatDate';
import NotFoundComponent from '../../components/NotFoundComponent';
import useResponsiveStyles from '../../functions/ResponsiveStyles';

const PaymentSuccessfulScreen = ({ }) => {
  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);

  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const IdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);

  const Id = navigationParams?.OrderId || Number(IdFromURL);

  const IsIdValid = Number(Id);


  const styles = styling(responsiveStyles);
  const [bodymessge, setbodymessge] = useState('');
  const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

  const [isFirstTime,setIsFirstTime] = useState(true);
  const [loading, setLoading] = useState(true);
  const [errorOnRequest, setErrorOnRequest] = useState(false);
  const [reloadpage, setReloadPage] = useState(false);
  const [order, setOrder] = useState(null);
  // const order = {
  //   orderNumber: '123456',
  //   orderDate: '2024-07-14',
  //   amountPaid: '$99.99',
  //   items: [
  //     { name: 'Sterling Silver Pendant', quantity: 1, price: '$49.99' },
  //     { name: 'Gold Necklace', quantity: 1, price: '$50.00' },
  //   ],
  //   totalAmount: '$99.99'
  // };
  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };

  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Payment Successful";
    }
    const fetchData = async () => {
      try {
        setLoading(true);
        const req = await GetOrderPaymentSuccessfulById(Id);
        if (req.isCompleted) {
          const vm = JSON.parse(req.answer);
          setOrder(vm);
        }
        else{
          setLoading(true);
          setErrorOnRequest(true);
        }
      } catch (err) {
        console.log(err);
        setLoading(true);
        setErrorOnRequest(true);
      }
      finally{
        setLoading(false);
      }
    };
    if(isFirstTime)
      {
        fetchData();
        setIsFirstTime(false);
      }
      else{
        // setLoading(true);
        setErrorOnRequest(false);
        if(errorOnRequest === true)
        {
          fetchData();
        }
      }
    if (responsiveStyles.isWeb) {
      window.scrollTo(0, 0);
    }
    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );
    return () => backHandler.remove();
  }, [reloadpage]);

  // Example order object

  const formattedDate = order?.OrderDate ? formatDate(order?.OrderDate) : null;
  return (
    <Screen style={styles.container}
      userSettingsState={userSettingsState}
      navigation={navigation}
    >
          {
          !IsIdValid ? <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
          : errorOnRequest === true ?
         <ErrorComponent
         userSettingsState={userSettingsState}
          onPress={() => { setReloadPage(!reloadpage)}}
          />
        : loading === true ? 
        <View style={[styles.container]}>
      <LoadingScreen 
      userSettingsState={userSettingsState}
       />
    </View> 
    : (
      <View style={styles.container}>
        <View style={styles.header}>
          <GenericAlert
            userSettingsState={userSettingsState}
            navigation={navigation}
            bodymessge={bodymessge}
            visible={GenericAlertVisible}
            setVisible={setGenericAlertVisible}
          />
        </View>
        <View style={styles.content}>
          <Text style={styles.heading}>{i18n.t('paymentSuccessful.header')}</Text>
          <Text style={styles.thankYouNote}>{i18n.t('paymentSuccessful.thankYouNote1')} {order?.FullName}{i18n.t('paymentSuccessful.thankYouNote2')}</Text>
          <View style={styles.orderSummary}>
            <Text style={styles.orderTitle}>{i18n.t('paymentSuccessful.orderTitle')}</Text>
            <Text style={styles.orderDescription}>{i18n.t('paymentSuccessful.orderNumber')} {order?.OrderNumber}</Text>
            <Text style={styles.orderDescription}>{i18n.t('paymentSuccessful.orderDate')} {formattedDate}</Text>
            <Text style={styles.orderDescription}>{i18n.t('paymentSuccessful.ItemsPurchased')}</Text>
            {order?.Items?.map((item, index) => (
              <>
              <Text style={styles.orderDescription} key={index}>{item.ProductName} - {item.Quantity} x {item.UnitPrice}</Text>
              {item.FrontSideText ? <Text style={styles.orderDescription} key={index}>• {i18n.t('store.ItemDetailsScreen_EngravingTextContainer_frontSideText_label')}: {item.FrontSideText}</Text> : null}
              {item.BackSideText ? <Text style={styles.orderDescription} key={index}>• {i18n.t('store.ItemDetailsScreen_EngravingTextContainer_backSideText_label')}: {item.BackSideText}</Text> : null}
              </>
            ))}
            <Text style={[styles.orderDescription, styles.price,]}>{i18n.t('paymentSuccessful.total')} {order?.Subtotal}₪</Text>
            <Text style={[styles.orderDescription, styles.price, {fontWeight: 'bold'}]}>{i18n.t('paymentSuccessful.amountPaid')} {order?.Total}₪</Text>
          </View>
          <Text style={styles.nextSteps}>
            {i18n.t('paymentSuccessful.nextSteps')}
          </Text>
          <Text style={styles.thankYouNote}>
          {i18n.t('paymentSuccessful.thankYouNoteFinal')}
          </Text>
          <View style={styles.ctaButtons}>
            <ButtonTypeButton
            userSettingsState={userSettingsState}
            style={styles.ctaButton} 
            onPress={() => {
              navigateToScreen(navigation, 'StoreFlow', `Home`, null, null);
            }}
            >
              <Text style={styles.ctaButtonText}>{i18n.t('paymentSuccessful.continueShoppingButton')}</Text>
            </ButtonTypeButton>
            <ButtonTypeButton
            userSettingsState={userSettingsState}
            style={styles.ctaButton} 
            onPress={() => {
              navigateToScreen(navigation, 'AccountFlow', `OrderDetails`, { Id: order.Id }, order.Id);
            }}
              >
              <Text style={styles.ctaButtonText}>{i18n.t('paymentSuccessful.orderDetailsButton')}</Text>
            </ButtonTypeButton>
          </View>
          <Text style={styles.contactInfo}>
          {i18n.t('paymentSuccessful.contactInfo')} {i18n.t('footer.Email')}.
          </Text>
        </View>
      </View>
    )}
    </Screen>
  );
};


PaymentSuccessfulScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    padding: 20,
    height: fixedScreenHeight,
  },
  content: {
    flex: 1,
    alignItems: 'center',
  },
  heading: {
    fontSize: responsiveStyles.fontSize_H3,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontWeight: 'bold',
    marginVertical: 20,
  },
  thankYouNote: {
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    marginVertical: 10,
    textAlign: 'center',
  },
  orderSummary: {
    width: '100%',
    marginVertical: 20,
    alignItems:'center',

    // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? '30%' : '70%',
    // alignItems:'flex-start',
    // backgroundColor:'blue'
  },
  orderTitle: {
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  orderDescription: {
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    marginBottom: 10,
  },
  price: {
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  nextSteps: {
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    marginVertical: 10,
    textAlign: 'center',
  },
  ctaButtons: {
    flexDirection: 'row',
    justifyContent: !responsiveStyles.isMobile ? 'space-evenly' : 'space-around',
    width: '100%',
    marginVertical: 20,
  },
  ctaButton: {
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.buttonBackgroundColor,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.1 : responsiveStyles.screenWidth * 0.3,
    minWidth: !responsiveStyles.isMobile  ? 200 : null,
  },
  ctaButtonText: {
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  contactInfo: {
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    marginVertical: 20,
  },
});

export default PaymentSuccessfulScreen;
