import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { FlatList } from "react-native-gesture-handler";
import ImageProfile from "../images/ImageProfile";
import JewleryDisplayTypeComponent from "../myAccountScreen/JewleryDisplayTypeComponent";
import JewleryListComponent from "../myAccountScreen/JewleryListComponent";
import ProfileListComponent from "../myAccountScreen/ProfileListComponent";
import ProfileDisplayTypeComponent from "../myAccountScreen/ProfileDisplayTypeComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const ProfilesComponent = ({ 
    userSettingsState, 
    navigation, 
    cleanup,
    profiles = [], 
    jewlery = [], 
    RestoreUserProfile,
    profileListDisplayType,
    setProfileListDisplayType,
    displayType = 'Profiles',
    setDisplayType,
  }) => {

    // const [displayType, setDisplayType] = useState('Profiles');

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
    console.log(`profiles is`);
    console.log(profiles);
  return (
<View style={styles.container}>
      <ProfileListComponent 
      userSettingsState={userSettingsState}
      displayType={displayType}
      setDisplayType={setDisplayType}
      profileListDisplayType={profileListDisplayType}
      setProfileListDisplayType={setProfileListDisplayType}
      navigation={navigation} 
      cleanup={cleanup}
      data={profiles} 
      RestoreUserProfile={RestoreUserProfile} 
      jewlery={jewlery}
      />
</View>

  );
}

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex:1,
    marginHorizontal: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.18
    : !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 
    : responsiveStyles.screenWidth * 0.1,
    marginTop: 10,

    },
});

export default ProfilesComponent;