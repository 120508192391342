import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import i18n from "../../hooks/useI18n";

import ImageProfile from "../images/ImageProfile";
import { GlobalStyles } from "../../constants/styles";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { getPaddingScreen } from "../../functions/ScreenDimensionsLogic";

  const UserImageComponent = ({ userSettingsState, user }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const paddingScreen = getPaddingScreen(responsiveStyles.screenWidth);
    const styles = styling(responsiveStyles, paddingScreen);
  return (
    <View style={styles.userPhotoContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
   uri={user?.UserImage}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
  userName={user?.UserName}
    />
  </View>
  );
}
const styling = (responsiveStyles, paddingScreen) => StyleSheet.create({
  userPhotoContainer: {
    justifyContent:'center',
    alignItems:'center',
    marginTop: 30,
    // backgroundColor: 'blue',
    // marginHorizontal: paddingScreen * 2,
  },
  userPhoto: {
    // width: 150,
    // height: 150,
    width: responsiveStyles.screenWidth > 1200 ? 200 : 150,
    height: responsiveStyles.screenWidth > 1200 ? 200 : 150,
    borderRadius: 100,
    borderWidth: 4,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    marginBottom: 10,
  },
});

export default UserImageComponent;