export default {
    Email: `support@scangems.com`,
    Phone: `0587257993`,
    AboutUs: "About Us",
    AboutUs_Description: "Scan a TMJ Pendant to learn about and interact with those who you love. You can also begin documenting",
    AboutUs_Address: `70 Address, City, Country PostCode`,
    FooterLinks_Title: "Quick Links",
    FooterLinks_Home: "HOME",
    FooterLinks_AboutUs: "ABOUT US",
    FooterLinks_AMBASSADOR: "BECOME AN AMBASSADOR",
    FooterLinks_Help: "HELP CENTER",
    FooterLinks_Terms: "TERMS & CONDITIONS",
    FooterLinks_Privacy: "PRIVACY",
    FooterLinks_ContactUs: "CONTACT US",
    FooterLinks_Tutorial: "TUTORIALS",
   };