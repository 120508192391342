import React, { useEffect } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Image, Dimensions, useWindowDimensions, Linking, Platform} from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { Video } from "expo-av";
import { Asset } from "expo-asset";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import QRCodeGenerator from "../QRCodeGenerator";
import { getAsset } from "../../functions/getAsset";
import VideoMediaPrimary from "../images/VideoMediaPrimary";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import ImageSlider from "../media/ImageSlider";

  const TutorialContainer = ({ 
    userSettingsState,
    durationTime,
    videoUri,
    title1,
    title2,
    text,
    onPress,
    stylesContainer,
    titleStyle,
    showButton = true,
    isOpposite = false,
    isFromHome = false,
    shouldPaddingBottom = true,
    isSetVideoSlider = false,
    handleVideoLayout = null,
 }) => {
  // const isFromHome = true;
  const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles, isOpposite, shouldPaddingBottom);

  return (
    <View style={[styles.container, stylesContainer]}>
      <View>
        <Text style={[styles.title, styles.titleBold, titleStyle]}>{title1}<Text style={[styles.title, titleStyle]}>{title2}</Text></Text>
      </View>
      <View style={styles.subContainer}
      onLayout={(event) => {
        if(handleVideoLayout){
          handleVideoLayout(event);
        }
      }}
      >
      <ImageSlider
    userSettingsState={userSettingsState} 
    images={
      [{
      type: 2, // video
      uri: videoUri,
      duration: durationTime,
      width: 1920,
      height: 1040,
    }]
  }
  isFromHome={true}
  isFromTutorialContainer={isSetVideoSlider}
    // isFromTributes={true}
    // isThumbnail={true}
     />
      {/* <View style={styles.videoContainer}>
      <VideoMediaPrimary
       videoUri={videoUri}
       isAudio={false}
       durationTime={durationTime}
       userSettingsState={userSettingsState}
       isDisplaying={false}
       videoStyle={styles.userPhoto}
       resizeMode='contain'
       showFullScreen={true}
       RenderSliderWidth={"95%"}
       isStyleWidthFullWhenFullScreen={true}
       fullScreenZindex={!responsiveStyles.isWeb ? 999 : null}
      //  bottom={
      //   shouldPaddingBottom ? 
      //   responsiveStyles.isMobile ? -responsiveStyles.screenWidth * 0.15 : null
      //   :responsiveStyles.isMobile ? -responsiveStyles.screenWidth * 0.25 : null
      // }
       />
       </View> */}
       <View>
        {isFromHome ? null :
        <>
        <View style={styles.textContainer}>
       {text}
       </View>
      { showButton ?
       <View style={styles.buttonContainer}>
        <ButtonTypeButton
              userSettingsState={userSettingsState}
                    style={styles.button}
                    onPress={onPress}
                    >
                      {i18n.t('tutorial.readMoreButton')}
          </ButtonTypeButton>
          </View>
          : null }
       </>}
       </View>
       </View>
    </View>

  );
}
const styling = (responsiveStyles, isOpposite, shouldPaddingBottom) => StyleSheet.create({

  container: {
    // flex: 1,
    // paddingBottom: responsiveStyles.isMobile || responsiveStyles.isTablet ? 40 : null,
    paddingVertical: 20,
    // paddingHorizontal: responsiveStyles.isMobile || responsiveStyles.isTablet ? 20 : null,
    transform: responsiveStyles.isMobile || responsiveStyles.isTablet ? [ { scale: 0.9 } ] : [ { scale: 1 } ],
  },
  subContainer: {
    // flex:1,
    flexDirection: responsiveStyles.isMobile || responsiveStyles.isTablet ? null 
    : responsiveStyles.isRTL ? isOpposite ? 'row-reverse' : 'row' 
    : isOpposite ? 'row' : 'row-reverse',
    // backgroundColor: 'blue',
  },
  userPhoto: {
    flex: null,
    width:  responsiveStyles.isMobile || responsiveStyles.isTablet 
    ? responsiveStyles.screenWidth : responsiveStyles.screenWidth * 0.3,
    // height:  responsiveStyles.isMobile || responsiveStyles.isTablet ? 350 : responsiveStyles.screenWidth * 0.3,
    minWidth: 350,
    maxWidth:  responsiveStyles.isMobile || responsiveStyles.isTablet 
    ? responsiveStyles.screenWidth * 0.8 : responsiveStyles.screenWidth * 0.3,
    maxHeight:  
    (responsiveStyles.isMobile || responsiveStyles.isTablet) ?
     responsiveStyles.isWeb  ? 350
     : null // mobile
     : responsiveStyles.screenWidth * 0.3,
    aspectRatio: 16 / 9,
    // height: responsiveStyles.isMobile || responsiveStyles.isTablet 
    // ? responsiveStyles.screenWidth * (16/9) : responsiveStyles.screenWidth * 0.3 * (16/9),
    
  },
  videoContainer: 
  { 
    flex:1, 
    maxHeight: (responsiveStyles.isMobile || responsiveStyles.isTablet) && responsiveStyles.isWeb 
    ? 350 : responsiveStyles.screenWidth * 0.3,
    // paddingBottom: responsiveStyles.isMobile ? 20 : null,
    // backgroundColor:'blue',
    // justifyContent:'center',

    // width:  responsiveStyles.isMobile || responsiveStyles.isTablet 
    // ? responsiveStyles.screenWidth : responsiveStyles.screenWidth * 0.3,
    minHeight: responsiveStyles.isMobile || responsiveStyles.isTablet 
    ? responsiveStyles.screenWidth * (9/16) - 20 : null,
    // paddingTop: 
    // shouldPaddingBottom ? 
    //     responsiveStyles.isMobile ? -responsiveStyles.screenWidth * 0.15 : null
    //     :responsiveStyles.isMobile ? -responsiveStyles.screenWidth * 0.25 : null,
    
  },
  title: {
    fontSize: responsiveStyles.fontSize_H4,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
    paddingBottom: 20,
    maxWidth:  responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 : null,
    alignSelf: 'center',
    minHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 100 : null,
  },
  titleBold: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H3,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  textContainer: {
    // maxHeight:  responsiveStyles.isMobile || responsiveStyles.isTablet ? 350 : responsiveStyles.screenWidth * 0.3,
    // height:  responsiveStyles.isMobile || responsiveStyles.isTablet ? 350 : responsiveStyles.screenWidth * 0.3,
    paddingTop: responsiveStyles.isMobile || responsiveStyles.isTablet ? 20 : null,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor:'blue',
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : responsiveStyles.screenWidth,
  },
  button: {
    width: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.1 - (10 * 3)
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.screenWidth * 0.4,
    minWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 80 : null,
    // width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.06 - (10 * 3) : responsiveStyles.screenWidth * 0.4,
    paddingTop:10,
},
});

export default TutorialContainer;