import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

const ColorPickerContainer = ({ userSettingsState, foundItem }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const [hoveredColor, setHoveredColor] = useState(null);

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.text}>{i18n.t('store.ColorPickerContainer_title')}:</Text>
      </View>

      <TouchableOpacity
        onMouseEnter={() => setHoveredColor('silver')}
        onMouseLeave={() => setHoveredColor(null)}
        style={[styles.colorContainer, { backgroundColor: 'silver' }]}
        activeOpacity={1}
      >
        {hoveredColor === 'silver' && (
          <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.ColorPickerContainer_silver')}</Text>
          </View>
        )}
      </TouchableOpacity>

      {/* Additional color containers */}
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    marginBottom: 20,
  },
  colorContainer: {
    width: 25,
    height: 25,
    borderRadius: 100,
    marginLeft: !responsiveStyles.isRTL ? 20 : null,
    marginRight: responsiveStyles.isRTL ? 20 : null,
    position: 'relative', // Needed for the tooltip
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  tooltip: {
    position: 'absolute',
    top: -40, // Position above the color container
    left: '50%',
    transform: [{ translateX: '-50%' }], // Corrected syntax
    padding: 5,
    backgroundColor: 'black',
    borderRadius: 5,
    zIndex: 1, // Ensure tooltip is above other content
  },
  tooltipText: {
    color: 'white',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
});

export default ColorPickerContainer;
