import React, { useState, useRef, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, Image, ScrollView, useWindowDimensions, Platform } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { GlobalStyles } from '../../constants/styles';
import VideoMediaPrimary from '../images/VideoMediaPrimary';
import { formatTimeSpan } from '../../functions/convertDurationToTimeSpan';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import VideoImage from '../images/VideoImage';


let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}
const ImageSlider = ({
    userSettingsState,
    images = [],
    durationOfFiles = [],
    isFromImageMedia = false,
    isFromManagePost = false,
    paddingScreenFromManagePost = 0,
    isFromItemDetails = false,
    isFromTributes = false,
    isThumbnail = false,
    isFromHome = false,
    isFromTutorialContainer = false,
     }) => {

        const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles, isFromImageMedia, isFromManagePost, paddingScreenFromManagePost, isFromItemDetails, isFromTributes, isFromHome);

    const [enableScroll, setEnableScroll] = useState(true);

    const [currentIndex, setCurrentIndex] = useState(0);

    const [isPlaying, setIsPlaying] = useState(true);

    const handleScroll = (event) => {
        const { contentOffset, layoutMeasurement, contentSize } = event.nativeEvent;
        const currentOffset = contentOffset.x;
        const currentPage = Math.floor(currentOffset / layoutMeasurement.width);
        setIsPlaying(true);
        setCurrentIndex(currentPage);
    };

    const handleScrollEnabled = (scrollEnabled) => {
        setEnableScroll(scrollEnabled);
    };    const scrollViewRef = useRef(null);

    const goToNextImage = () => {
        if (currentIndex < images.length - 1) {
        setIsPlaying(true);
            setCurrentIndex(currentIndex + 1);
            scrollToIndex(currentIndex + 1);
        }
    };

    const goToPreviousImage = () => {
        if (currentIndex > 0) {
        setIsPlaying(true);
            setCurrentIndex(currentIndex - 1);
            scrollToIndex(currentIndex - 1);
        }
    };

    const scrollToIndex = (index) => {
        scrollViewRef.current.scrollTo({
            x: index * responsiveStyles.screenWidth,
            animated: true,
        });
    };

    const [scrollViewHeight, setScrollViewHeight] = useState(0);    
    useEffect(() => {
        if(responsiveStyles.isWeb){
        // Access the DOM element by class name
        let elements = document.getElementsByClassName('r-scrollSnapAlign-cpa5s6');
        if(!elements || elements.length === 0){
            elements = document.getElementsByClassName('r-cpa5s6');
          }
        if (elements.length > 0) {
          // Apply the styles directly
          for (let element of elements) {
            element.style.justifyContent = 'center';
          }
        }
    }
      }, [currentIndex]);
    return (
        <View style={styles.container}>
        <ScrollView
            horizontal
            pagingEnabled
            showsHorizontalScrollIndicator={false}
            scrollEnabled={enableScroll}
            onScroll={handleScroll}
            scrollEventThrottle={16}
            // style={[styles.scrollView]}
            style={[styles.scrollView, { marginTop: isFromTributes ? -(scrollViewHeight * 0.1) : null}]}
            ref={scrollViewRef}
            onLayout={(event) => {
                let {height} = event.nativeEvent.layout;
                setScrollViewHeight(height);
              }}
        >
                {images.map((item, index) => {
                const IsAudio = (item.type?.toString()?.includes("audio") || item.type === 3);
                const IsVideo = (item.type?.toString()?.includes("video")
                || (IsAudio ? false : item.type?.toString()?.includes("mp4"))
                || item.type === 2);
                const durationOfItem = IsVideo || IsAudio ?
                formatTimeSpan(item.duration) ? formatTimeSpan(item.duration) :
            responsiveStyles.isWeb ? (durationOfFiles.find(s => s.uri === item.uri)?.duration || formatTimeSpan(item.duration)) :
            formatTimeSpan(item.duration) : null;

            let indexList = [(index - 1), index, (index + 1)];
            if(!indexList.includes(index)){
                return null;
            }
                if(responsiveStyles.isWeb && currentIndex !== index){
                    return null;
                }

                let DimensionsExists = false;
                let aspectRatio = null;
                let targetWidth = null;
                let targetHeight = null;
                if(item.width && item.width > 0 && item.height && item.height > 0){
                    aspectRatio = item.width / item.height;
                    DimensionsExists = true;
                    targetWidth = responsiveStyles.screenWidth;
                    targetHeight = responsiveStyles.screenWidth / aspectRatio;
                    // if (targetHeight > responsiveStyles.screenHeight) {
                    //     targetHeight = responsiveStyles.screenHeight;
                    //     targetWidth = responsiveStyles.screenHeight * aspectRatio;
                    //   }
                    if(aspectRatio < 1){
                        targetHeight = responsiveStyles.screenWidth * aspectRatio;
                    }
                }
                if(
                    item.duration?.startsWith("00:00:17") 
                    // || item.duration?.startsWith("00:00:46") 
                    || item.duration?.startsWith("00:00:08")
                ){
                    console.log(`
                        item.duration: ${item.duration}
                        item.width is ${item.width}
                        item.height is ${item.height}
                        aspectRatio: ${aspectRatio}
                        targetWidth: ${targetWidth}
                        targetHeight: ${targetHeight}
                        `);
                        console.log();
                }
                    return <View 
                    style={styles.itemContainer}
                    >
                {
                    IsAudio || IsVideo ?
                        (
                        <VideoMediaPrimary
                            key={index}
                            videoUri={item.uri}
                            isAudio={IsAudio}
                            durationTime={durationOfItem}
                            // videoContainerStyle={{ width: responsiveStyles.screenWidth }}
                            videoStyle={[
                                // styles.userPhoto,
                                DimensionsExists ? 
                                {
                                    width: targetWidth,

                                    maxWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop
                                    ? 

                                    isFromHome ? responsiveStyles.screenWidth * 0.2
                                    : 
                                    responsiveStyles.screenWidth * 0.7

                                    :isFromHome ? responsiveStyles.screenWidth * 1
                                    : null,

                                    // maxWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop
                                    // ? responsiveStyles.screenWidth * 0.7 * 0.8 // (* 0.8) is the transform
                                    // : responsiveStyles.screenWidth * 1 * 0.8,

                                    // marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop
                                    // ? responsiveStyles.screenWidth * 0.1
                                    // : responsiveStyles.screenWidth * 0.1,
                                    // backgroundColor:'blue',
                                    height: targetHeight,
                                    // backgroundColor:'blue',
                                    minHeight: '100%',
                                    maxHeight: 
                                    responsiveStyles.isDesktop || responsiveStyles.isLaptop 
                                    ? responsiveStyles.screenWidth * 0.4
                                    : null,
                                }
                                : styles.userPhoto,
                            ]}
                            resizeMode='contain'
                            userSettingsState={userSettingsState}
                            isDisplaying={isFromTributes || isFromHome ? false : currentIndex === index}
                            onSliderTouchStart={() => setEnableScroll(false)}
                            onSliderTouchEnd={() => setEnableScroll(true)}
                            isThumbnail={isThumbnail}
                            showFullScreen={isFromTributes || isFromHome ? true : false}
                            RenderSliderWidth={isFromTributes || isFromHome ? "95%" : '100%'}
                            isFromTributes={isFromTributes}
                            isStyleWidthFullWhenFullScreen={isFromHome ? true : false}
                            isFromTutorialContainer={isFromTutorialContainer}
                            />
                        )
                        : (
                            <FastImage
                                key={index}
                                style={[
                                    // styles.image,
                                    DimensionsExists ? 
                                    {
                                        width: targetWidth,
                                        // maxWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop
                                        // ? responsiveStyles.screenWidth * 0.7 * 0.8 // (* 0.8) is the transform
                                        // : responsiveStyles.screenWidth * 1 * 0.8,

                                        maxWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop
                                        ? responsiveStyles.screenWidth * 0.7
                                        : null,
                                        height: targetHeight,
                                        // backgroundColor:'blue',
                                        minHeight: '100%',
                                        maxHeight: 
                                        responsiveStyles.isDesktop || responsiveStyles.isLaptop 
                                        ?responsiveStyles.screenWidth * 0.4
                                        : null,
                                        backgroundColor:
                                        (isFromItemDetails) ? null :
                                        'black',
                                    }
                                    : styles.image,
                                ]}
                                source={{
                                    uri: item.uri,
                                    // priority: FastImage.priority.normal,
                                }}
                                // resizeMode="cover"
                                resizeMode='contain'
                            />
                            // null
                        )
                }
                </View>
                })}
            </ScrollView>
            {currentIndex > 0 && (
            <TouchableOpacity
          style={[styles.showcaseNextPreviousButtonContainer, styles.PreviousContainer]}
          onPress={goToPreviousImage}
          >
            <AntDesign name="left" size={16} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
          </TouchableOpacity>
          )}
          {currentIndex < images.length - 1 && (
          <TouchableOpacity
          style={[styles.showcaseNextPreviousButtonContainer,
            styles.NextContainer,
          ]}
          onPress={goToNextImage}
          >
          <AntDesign name="right" size={16} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
          </TouchableOpacity>
          )}
        </View>
    );
};

const styling = (responsiveStyles, isFromImageMedia, isFromManagePost, paddingScreenFromManagePost, isFromItemDetails, isFromTributes, isFromHome) => StyleSheet.create({
    container: {
        flex: isFromHome && !responsiveStyles.isWeb ? null : 1,
        marginLeft: 
        isFromHome ? null :
        responsiveStyles.isDesktop || responsiveStyles.isLaptop ? -(responsiveStyles.screenWidth * 0.2) : null,
        paddingHorizontal: 
        (isFromItemDetails) && responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ?  10 
        : (isFromTributes) && responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? 20
        : null,
       minHeight: isFromHome && !responsiveStyles.isWeb ? responsiveStyles.screenHeight * 0.5 : null,
        // backgroundColor:'blue'
    },
    itemContainer: {
        // backgroundColor:'yellow',
        height: 
        responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? 
        isFromHome ? responsiveStyles.screenWidth * 0.25 * (9/16)
        : responsiveStyles.screenWidth * 0.4 
        : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.8


        : isFromHome ? responsiveStyles.screenHeight * 0.25 * (16/9)

        : responsiveStyles.screenWidth,
        // : isFromImageMedia ? responsiveStyles.screenWidth : null
        // : null,
        justifyContent:'center',
        // backgroundColor:'black'
        
    },
    scrollView: {
        // height: 200, // Set the desired height for the ImageSlider
        width: 

        // isFromTributes
        // isFromTributes 
        // ? responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        // ? responsiveStyles.screenWidth * 0.8 : responsiveStyles.screenWidth * 0.8

        // isFromItemDetails
        isFromHome && responsiveStyles.isWeb && (responsiveStyles.isDesktop || responsiveStyles.isLaptop ) ? null :
         (isFromItemDetails) 
        ? responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? responsiveStyles.screenWidth * 0.5 : responsiveStyles.screenWidth


        : responsiveStyles.screenWidth,


        // backgroundColor:'blue',

        marginLeft: 
        isFromHome ? null :
        responsiveStyles.isDesktop || responsiveStyles.isLaptop ?
        // (isFromManagePost) ? null :
         isFromTributes ? (responsiveStyles.screenWidth * 0.1)
        // : isFromTributes ? (responsiveStyles.screenWidth * 0.2)
        : (responsiveStyles.screenWidth * 0.2) 
        : isFromTributes ? -(responsiveStyles.screenWidth * 0.1)
        // : isFromTributes 
        // ? responsiveStyles.isWeb ? responsiveStyles.isTablet ? (responsiveStyles.screenWidth * 0.03) : (responsiveStyles.screenWidth * 0.02) : 0
        : null,
        transform: isFromTributes ? [{ scale: 0.8 }] : [{ scale: 1 }],
        // marginTop: isFromTributes ? -responsiveStyles.screenHeight * 0.08 : null,
    },
    scrollViewContent: {
        alignItems: 'center',
    },
    button: {
        padding: 10,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
    },
    showcaseNextPreviousButtonContainer: {
        position: 'absolute',
        top: '50%',
        transform: [{ translateY: -12 }], // Adjust based on icon size
        backgroundColor: GlobalStyles.colors.ImageMediaModalButtonColor,
        borderRadius: 100,
        padding: 5,
        justifyContent:'center',
        alignItems:'center',
      },
      PreviousContainer: {
        left: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?
        // isFromManagePost ? responsiveStyles.screenWidth * 0.01
        isFromManagePost ? responsiveStyles.screenWidth * 0.22
        : isFromImageMedia ? responsiveStyles.screenWidth * 0.21
        : (isFromItemDetails) ? responsiveStyles.screenWidth * 0.205
        : (isFromTributes) ? (responsiveStyles.screenWidth * 0.21 + 20)
        : responsiveStyles.screenWidth * 0.27
        : 10,
      },
      NextContainer: {
        right: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?
        // isFromManagePost ? responsiveStyles.screenWidth * 0.21
        isFromManagePost ? responsiveStyles.screenWidth * 0.03
        : isFromImageMedia ? responsiveStyles.screenWidth * 0.01
        : (isFromItemDetails) ? responsiveStyles.screenWidth * 0.005
        : (isFromTributes) ? (responsiveStyles.screenWidth * 0.01 + 20)
        : responsiveStyles.screenWidth * 0.47


        : isFromManagePost ? -responsiveStyles.screenWidth * 0.29

        : 10,
      },

      image: {
        width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?
        isFromManagePost ? responsiveStyles.screenWidth * 0.7  - paddingScreenFromManagePost
        : isFromImageMedia ? responsiveStyles.screenWidth * 0.8
        : (isFromItemDetails) ? responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? responsiveStyles.screenWidth * 0.42
        : responsiveStyles.screenWidth
        
        : (isFromTributes) ? responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? responsiveStyles.screenWidth * 0.7
        : responsiveStyles.screenWidth

        : (isFromHome) ? responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? responsiveStyles.screenWidth * 0.25
        : responsiveStyles.screenWidth

        : responsiveStyles.screenWidth * 0.6
        : responsiveStyles.screenWidth,
        height: '100%',
        maxHeight: 
        (isFromItemDetails || isFromTributes) 
        ? responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenHeight * 0.8 
        : null
        : null,
        backgroundColor:
        (isFromItemDetails) ? null :
        'black',
        // GlobalStyles[ThemeValue]?.primaryBackgroundColor,
    },
      userPhoto: {
        // width: imageWidth,
        // height: imageWidth,
        width: 
        responsiveStyles.isDesktop || responsiveStyles.isLaptop ?
        // isFromManagePost ? responsiveStyles.screenWidth * 1
        isFromManagePost ? responsiveStyles.screenWidth * 0.7 - paddingScreenFromManagePost
        : isFromImageMedia ? responsiveStyles.screenWidth * 0.8
        : isFromTributes ? responsiveStyles.screenWidth * 0.7
        : isFromHome ?  responsiveStyles.screenWidth * 0.25
        : responsiveStyles.screenWidth * 0.6
        : responsiveStyles.screenWidth,
        height: 
        responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.4 
        : responsiveStyles.screenWidth,
        // aspectRatio: 848 / 480,
        // marginLeft: responsiveStyles.screenWidth * 0.2,
        // maxWidth: 300,
        //   maxHeight: 300,
        backgroundColor:
        (isFromItemDetails) ? null :
        'black',
        marginBottom: 15,
      },
      videoStyle: {
        width: '100%',
        aspectRatio: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 2 / 1 : 1,
        maxHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : 300,
        marginBottom: 10,
      },
});

export default ImageSlider;
