import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, FlatList, Image, Dimensions, TouchableOpacity, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { Context as ShopContext } from "../../context/ShopContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import BackgroundImageComponent from '../../components/itemListScreen/BackgroundImageComponent';
import TitleComponent from '../../components/itemListScreen/TitleComponent';
import CommentSectionListComponent from '../../components/displayComponent/CommentSectionListComponent';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import { StoreItems } from '../../constants/store/Store_Items';
import StoreBreadcrumbs from '../../components/store/StoreBreadcrumbs';
import { getNavigationParams } from '../../functions/navigationLogic';
import { navigateToScreen } from '../../navigationResponsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getItem } from '../../modules/Storage';
import NotFoundComponent from '../../components/NotFoundComponent';
import BuisnessFooter from '../../components/store/BuisnessFooter';
import { rearrangeDataForRTL } from '../../functions/rearrangeDataForRTL';
import HoverableTouchableOpacity from '../../components/HoverableTouchableOpacity';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import ItemListEmptyListComponent from '../../components/displayComponent/ItemListEmptyListComponent';

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}

const ItemListScreen = ({  }) => {

  const { state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);

  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const IdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);

  console.log(`IdFromURL is ${IdFromURL}`);
  const Id = navigationParams?.Id || Number(IdFromURL)|| 0;
  console.log(`Id is ${Id}`);

  const styles = styling(responsiveStyles);

  const [bodymessge, setbodymessge] = useState('');
  const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
  const { state: shopState, addOrUpdateItemToCart } = useContext(ShopContext);
  const [openCart, setOpenCart] = useState(false);
  const [numColumns, setNumColumns] = useState(responsiveStyles.screenWidth > 1024 ? 3 : 2);

  useEffect(() => {
    if(responsiveStyles.isWeb){
      document.title = "Item List";
    }
    const onChange = ({ window }) => {
      const newWidth = window.width;
      setNumColumns(newWidth > 1024 ? 3 : 2);
      // const newNumColumns = responsiveStyles.isMobile ? 2 : 3;
      // setNumColumns(newNumColumns);
    };

    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction,
    );
    if(responsiveStyles.isWeb){
      window.scrollTo(0, 0);
    }
    if(responsiveStyles.isWeb){
    Dimensions.addEventListener('change', onChange);
    }
    return () =>{
      backHandler.remove();
      if(responsiveStyles.isWeb){
        Dimensions.removeEventListener('change', onChange);
      }
    } 
  }, []);

  
  let storeItem = StoreItems.find(s => s.Id === Id) ? StoreItems.find(s => s.Id === Id) : null;
  const data = storeItem ? storeItem.Items.filter(s => s.IsActive === true) : [];
  const title = storeItem ? responsiveStyles.Language === "he-IL" ? storeItem.Title_he : storeItem.Title_en : "";
  const title_i18n = storeItem ? storeItem.Title : "";
    
  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
    return true;
  };

  

  return (
    <Screen
      userSettingsState={userSettingsState}
      navigation={navigation}
      showCart={true}
      openCart={openCart}
      setOpenCart={setOpenCart}
    >
    {!storeItem ? 
    <View style={[styles.container]}>
      <View>
      <StoreBreadcrumbs 
      userSettingsState={userSettingsState}
      navigation={navigation}
      itemListName={"404"}
      />
      <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
      </View>
    </View>
    :
      <ScrollView style={styles.container}>
        <View>
        <View style={styles.header}>
          <GenericAlert
            userSettingsState={userSettingsState}
            navigation={navigation}
            bodymessge={bodymessge}
            visible={GenericAlertVisible}
            setVisible={setGenericAlertVisible}
          />
          <BackgroundImageComponent userSettingsState={userSettingsState} uri={storeItem?.Uri} />
          <View style={data.length === 0 ? null : styles.subContainer}>
            <TitleComponent userSettingsState={userSettingsState} title={i18n.t(`store.${title_i18n}`)} />
            <View style={{ paddingTop: 20 }}>
            {/* <View style={{ paddingTop: 150 - 85 - 20 }}> */}
              <StoreBreadcrumbs 
                userSettingsState={userSettingsState}
                navigation={navigation} 
                itemListName={title} 
              />
              <FlatList
                scrollEnabled={true}
                nestedScrollEnabled={true}
                style={styles.flatListContainer}
                contentContainerStyle={styles.flatListContentContainer} // Add this line
                data={responsiveStyles.isRTL ? rearrangeDataForRTL(data, numColumns) : data}
                numColumns={numColumns}
                key={numColumns} // Changing the key forces a fresh render
                renderItem={({ item, index }) => {
                  const isWithDiscount = item.Discount > 0;
                  const realPrice = item.Price;
                  const afterDiscount = isWithDiscount ? (item.Price * (100 - item.Discount) / 100).toFixed(2) : realPrice;

                  return (
                    <TouchableOpacity 
                    userSettingsState={userSettingsState}
                    style={styles.itemContainer}
                      onPress={() => {
                        navigateToScreen(navigation, 'StoreFlow', `ItemDetails`, { Id: item.Id }, item.Id);
                      }}
                    >
                      <HoverableTouchableOpacity 
                      onPress={() => {
                        navigateToScreen(navigation, 'StoreFlow', `ItemDetails`, { Id: item.Id }, item.Id);
                      }}
                      style={styles.itemImageContainer}
                      >
                        <FastImage
                          source={{ uri: item.Images?.length > 0 ? item.Images[0] : null }}
                          resizeMode='cover'
                          style={styles.image}
                        />
                      </HoverableTouchableOpacity>
                      <View style={styles.itemInfoContainer}>
                        <Text style={[styles.text, styles.title]}>{responsiveStyles.Language === "he-IL" ? item.Name_he : item.Name_en}</Text>
                        <View style={{ flexDirection: responsiveStyles.isRTL ? 'row' : 'row-reverse', alignItems: 'center', justifyContent: 'center' }}>
                          <Text style={[styles.text, isWithDiscount ? styles.itemBeforeDiscount : null]}>{realPrice} ₪</Text>
                          {isWithDiscount ? 
                            <Text style={[styles.text, styles.itemDetails, styles.itemAfterDiscount]}>{afterDiscount} ₪</Text>
                            : null
                          }
                        </View>
                        <View style={styles.buttonContainer}>
                          <ButtonTypeButton
                          userSettingsState={userSettingsState}
                            styleButton={{ 
                              // backgroundColor: GlobalStyles.colors.AddButton,
                               padding: 5 }}
                            style={styles.button}
                            onPress={() => {
                              addOrUpdateItemToCart(item, item.Id, 1);
                              setOpenCart(true);
                            }}
                          >
                            {i18n.t('store.AddToCart')}
                          </ButtonTypeButton>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                }}
                ListEmptyComponent={
                  <ItemListEmptyListComponent
                  userSettingsState={userSettingsState}
                  navigation={navigation}
                  />
                    }
              />
            </View>
          </View>
        </View>
        <BuisnessFooter
      userSettingsState={userSettingsState}
      navigation={navigation}
      />
        </View>
      </ScrollView>
}
    </Screen>
  );
};


ItemListScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  };
};

  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  subContainer: {
    flex: 1,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.8 : null,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  header: {
    
    paddingBottom: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenHeight * 0.1 : 10,
  },
  flatListContainer: {
    // marginTop: 20,
  },
  flatListContentContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexShrink: 1,
  },
  itemContainer: {
    marginBottom: 10,
    borderRadius: 15,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.21 : responsiveStyles.screenWidth * 0.48,
    // marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.5 : null,
    marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? !responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.04 : null : null,
    marginRight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.04 : null : null,
    marginHorizontal: 5,
    marginVertical: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 25 : null,
  },
  itemImageContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingTop: 20,
  },
  image: {
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.45,
    // height: 200,
    aspectRatio: 1 / 1,
    // resizeMode: 1,
    marginRight: !responsiveStyles.isRTL ? 10 : null,
    marginLeft: responsiveStyles.isRTL ? 10 : null,
    borderRadius: 15,
    paddingBottom: 20,
  },
  buttonContainer: {
    // alignItems: 'center',
    // flex: 1
    marginTop: 15,
    alignItems: 'center',
  },
  button: {
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.08 : responsiveStyles.screenWidth * 0.3,
  },
  itemInfoContainer: {
    marginHorizontal: 10,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 : null,
    marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? !responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.005 : null : null,
    marginRight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.005 : null : null,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
  },
  title: {
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    paddingVertical: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : 10,
  },
  itemBeforeDiscount: {
    textDecorationLine: 'line-through',
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  itemAfterDiscount: {
    paddingHorizontal: 5,
    color: GlobalStyles.colors.priceAfterDiscountColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
  },
});

export default ItemListScreen;
