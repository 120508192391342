import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

const TestScreen = ({  }) => {
    console.log(`TestScreen`);
  return <View>
    <Text>Test</Text>
  </View>

}

TestScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };


export default TestScreen;
