import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, FlatList } from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import { Context as ShopContext } from '../../context/ShopContext';
import ShoppingCartComponent from "./ShoppingCartComponent";
import DiscountComponent from "./DiscountComponent";
import TotalItemsComponent from "./TotalItemsComponent";
import CheckoutButtonComponent from "./CheckoutButtonComponent";
import FloatingCartHeaderComponent from "./FloatingCartHeaderComponent";
import { getDiscountPrice, getShippingFee, getTotalPrice, getTotalQuantity } from "../../functions/getShopInfo";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

  const ShoppingCartFullInfoComponent = ({ 
    userSettingsState, 
    showFloatingCartHeader = false,
    setIsStoreMenuOpen,
    isOrderSummery = false,
    showShippingFee = false,
    navigation,
    displayAsFinalProduct = false,
    isFromCartScreen = false,
    isFromStoreMenu = false,
  }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);

    const {state: shopState, addOrUpdateItemToCart, deleteItemFromCart } = useContext(ShopContext);
    const data = shopState.cart;
    const totalQuantity = getTotalQuantity(data);
    let totalPriceBeforeShippingFee = getTotalPrice(shopState, false, false);
    let shippingFee = getShippingFee(shopState);
    let discountFee = getDiscountPrice(shopState);
    let totalPriceAfterShippingFeeAndDiscount = getTotalPrice(shopState, true, true);
    let totalPriceAfterDiscount = getTotalPrice(shopState, false, true);

  const styles = styling(responsiveStyles, displayAsFinalProduct);
  return (
    <View style={isOrderSummery ? styles.orderSummeryContainer : null}>
      {
        showFloatingCartHeader ? 
        <FloatingCartHeaderComponent 
        userSettingsState={userSettingsState} 
        totalQuantity={totalQuantity}
        setIsStoreMenuOpen={setIsStoreMenuOpen}
        />
        : null
      }
      <ShoppingCartComponent userSettingsState={userSettingsState} data={data} navigation={navigation} />
      {data.length > 0 ?
      <>
      <DiscountComponent userSettingsState={userSettingsState} isFromStoreMenu={isFromStoreMenu} />
      <TotalItemsComponent
      userSettingsState={userSettingsState} 
      data={data} 
      totalQuantity={totalQuantity}
      totalPrice={totalPriceBeforeShippingFee}
      showShipping={showShippingFee}
      shippingFee={shippingFee}
      discountFee={discountFee}
      discountCode={shopState?.discount?.discountCode}
      totalPriceAfterShippingFee={totalPriceAfterShippingFeeAndDiscount}
      totalPriceAfterDiscount={totalPriceAfterDiscount}
      />
      { 
      isOrderSummery ? <>
      </>
      :
      <>
        <CheckoutButtonComponent
        userSettingsState={userSettingsState} 
        totalPrice={totalPriceAfterDiscount}
        setIsStoreMenuOpen={setIsStoreMenuOpen}
        navigation={navigation}
        />
      </>
      }
      </>
      : null }
    </View>
  );
}
const styling = (responsiveStyles, displayAsFinalProduct) => StyleSheet.create({
  orderSummeryContainer: { 
    // backgroundColor: GlobalStyles.colors.Lavender,
    padding: 30, 
    paddingTop: 0,
    marginBottom: 0,
    borderWidth: displayAsFinalProduct ? 0 : 1,
    borderColor: Gold,
  },
});

export default ShoppingCartFullInfoComponent;