import React, { useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, useWindowDimensions, Platform } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import TextInputWithErrorComponent from "./TextInputWithErrorComponent";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import ImageProfile from "../images/ImageProfile";
import DateComponent from "../inputs/item/DateComponent";
import ErrorComponentMessageError from "../error/ErrorComponentMessageError";
import { convertUTCtoLocal } from "../../functions/convertUTCtoLocal";
import { GenericAlert } from "../alert/GenericAlert";
import SelectDropdown from "react-native-select-dropdown";
import { Feather } from '@expo/vector-icons'; 
import { getPaddingScreen } from "../../functions/ScreenDimensionsLogic";
import BottomCardComponent from "../profileScreen/BottomCardComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { AntDesign } from '@expo/vector-icons';
import ManagePetProfileForm_PetTypeComponent from "./ManagePetProfileForm_PetTypeComponent";
import ManagePetProfileForm_GenderComponent from "./ManagePetProfileForm_GenderComponent";
import ManagePetProfileForm_EnergyLevelComponent from "./ManagePetProfileForm_EnergyLevelComponent";
import ManagePetProfileForm_Behavior_DogsComponent from "./ManagePetProfileForm_Behavior_DogsComponent";
import ManagePetProfileForm_Behavior_CatsComponent from "./ManagePetProfileForm_Behavior_CatsComponent";
import ManagePetProfileForm_Behavior_ChildrenComponent from "./ManagePetProfileForm_Behavior_ChildrenComponent";
import ManagePetProfileForm_Behavior_StragersComponent from "./ManagePetProfileForm_Behavior_StrangersComponent";
import ManagePetProfileForm_Behavior_OtherAnimalsComponent from "./ManagePetProfileForm_Behavior_OtherAnimalsComponent";
import ManagePetProfileForm_Header from "./ManagePetProfileForm_Header";
import ManagePetProfileForm_TrainedComponent from "./ManagePetProfileForm_TrainedComponent";
import ManagePetProfileForm_NatureComponent from "./ManagePetProfileForm_NatureComponent";
import ManagePetProfileForm_Health_AllergiesComponent from "./ManagePetProfileForm_Health_AllergiesComponent";
import ManagePetProfileForm_Health_SpayedComponent from "./ManagePetProfileForm_Health_SpayedComponent";
import ManagePetProfileForm_Health_MedicalIndicationsComponent from "./ManagePetProfileForm_Health_MedicalIndicationsComponent";
import ManagePetProfileForm_Health_VaccineComponent from "./ManagePetProfileForm_Health_VaccineComponent";
import ManagePetProfileForm_Health_MedicationComponent from "./ManagePetProfileForm_Health_MedicationComponent";
import ManagePetProfileForm_Vet_MicrochipNumberComponent from "./ManagePetProfileForm_Vet_MicrochipNumberComponent";
import ManagePetProfileForm_Vet_PhoneNumberComponent from "./ManagePetProfileForm_Vet_PhoneNumberComponent";
import ManagePetProfileForm_Vet_NameComponent from "./ManagePetProfileForm_Vet_NameComponent";
import ManagePetProfileForm_Vet_RabiesNumberComponent from "./ManagePetProfileForm_Vet_RabiesNumberComponent";

const ManagePetProfileForm = ({ 
    userSettingsState,
    inputs,
    setInputs,
    additionalInfoInputs,
    setAdditionalInfoInputs,
    submitHandler,
    Id = null,
    isAdmin = false,
    DeleteUserProfile = null,
    navigation,
    onLayoutChange,
}) => {

    

    const responsiveStyles = useResponsiveStyles(userSettingsState);
const paddingScreen = getPaddingScreen(responsiveStyles.screenWidth);

const widthOfInput = 
!responsiveStyles.isMobile ? responsiveStyles.screenWidth - (paddingScreen * 2) 
: responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.6 :  responsiveStyles.screenWidth * 0.90;
const paddingScreenInsideCustom = 
responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.08
: responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.08
: responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.1
: null;
const styles = styling(responsiveStyles, paddingScreen, paddingScreenInsideCustom, isAdmin);

    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const [basicInformationVisible, setBasicInformationVisible] = useState(true);
    const [behaviorVisible, setBehaviorVisible] = useState(false);
    const [healthVisible, setHealthVisible] = useState(false);
    const [vetVisible, setVetVisible] = useState(false);

    const DeleteUserProfileHandler = async () => {
        if(DeleteUserProfile){
            const response = await DeleteUserProfile();
            if(response.isCompleted === true) {
                responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
            } else {
                setbodymessge(response.message);
                setGenericAlertVisible(true);
            }
            setDeleteAlertVisible(false);
        }
    }

    const RelationshipList = i18n.t('RelationshipList');
    
    const [isLoading, setIsLoading] = useState(false);
    const isEditing = !!Id;
    const editable = isAdmin || !isEditing;
    function inputChangedHandler(inputIdentifier, enteredValue, isChanged) {
        setInputs((curInputs) => ({
            ...curInputs,
            [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
        }));
    }
    function additionalInfoInputChangedHandler(inputIdentifier, enteredValue, isChanged) {
        setAdditionalInfoInputs((curInputs) => ({
            ...curInputs,
            [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
        }));
    }

    const RelationshipListData = RelationshipList.map(item => item.Value);

    

    return (
        <View>
            
            <View style={styles.titleContainer}>
                <Text style={styles.title}>{isEditing ? i18n.t('account.MyAccount_ManageProfileForm_Edit') : i18n.t('account.MyAccount_ManageProfileForm_Create')} {i18n.t('account.MyAccount_ManageProfileForm_NewProfile')}</Text>
            </View>

            
        <View style={styles.container}>
            <GenericAlert
            userSettingsState={userSettingsState}
                navigation={navigation}
                bodymessge={bodymessge}
                visible={GenericAlertVisible}
                setVisible={setGenericAlertVisible}
            />
            <GenericAlert
            userSettingsState={userSettingsState}
                bodymessge={i18n.t('account.buttonmessage1DeleteAlert')}
                buttonmessage1={i18n.t('account.buttonmessage1Yes')}
                buttonmessage2={i18n.t('account.buttonmessage2No')}
                visible={deleteAlertVisible}
                setVisible={setDeleteAlertVisible}
                buttonmessage1onPress={DeleteUserProfileHandler}
            />
<TouchableOpacity style={styles.backgroundImageContainer}
            activeOpacity={editable ? 0.25 : 1}
            >
                <ImageProfile
                userSettingsState={userSettingsState}
                    uri={inputs.BackgroundImage.value}
                    imageStyle={styles.backgroundImage}
                    isBackgroundImage={true}
                    editable={editable}
                    isGroup={false}
                    showEditButton={true}
                    onImagePick={(data) => {
                        if(editable){
                            inputChangedHandler("BackgroundImage", data, true);
                        }
                    }}

                    isWithCrop={true}
                    // resizeMode={"contain"}
                />
            </TouchableOpacity>

            <View style={styles.imageContainer}
            activeOpacity={editable ? 0.25 : 1}
            >
                <ImageProfile
                userSettingsState={userSettingsState}
                    uri={inputs.Image.value}
                    imageStyle={styles.userPhoto}
                    editable={editable}
                    showEditButton={true}
                    onImagePick={(data) => {
                        if(editable){
                        inputChangedHandler("Image", data, true);
                        }
                    }}
                    isWithCrop={true}
                    isCircle={true}
                />
            </View>
            <View>
            {
                !isEditing ? null :
                isAdmin ?
                <Text style={styles.noteText}>{`${i18n.t('account.MyAccount_ManageProfileForm_NoteText')}`}</Text>
                : <Text style={styles.noteText}>{`${i18n.t('account.MyAccount_ManageProfileForm_NoteForNonAdmin')}`}</Text>
            }
            </View>
            <View style={styles.mainContainer}>
            <ManagePetProfileForm_Header
            userSettingsState={userSettingsState}
            onPress={() => {
                setBasicInformationVisible(!basicInformationVisible);
            }}
            text={`${i18n.t('account.MyAccount_ManagePetProfileForm_BasicInformation')}`}
            visible={basicInformationVisible}
            />
            {basicInformationVisible ? 
            <>
            <View style={[styles.textInputRowContainer, 
            { 
                // backgroundColor:'brown', 
                // marginHorizontal: paddingScreenInsideCustom,
                // justifyContent: "space-evenly"
            }
        ]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                        //   console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('PetName', event.nativeEvent.layout.y);
                          onLayoutChange('Breed', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.PetName.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("PetName", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_PetName')} *`}
                errorMessage={i18n.t('account.MyAccount_ManagePetProfileForm_PetName_ErrorMessage')}
                isRequired={true}
                isValid={inputs.PetName.isValid}
                styleTextInputContainer={styles.textInputName}
                
            />
            {console.log(`inputs.PetName.isValid is ${inputs.PetName.isValid}`)}
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.Breed.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("Breed", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_Breed')}`}
                isValid={inputs.Breed.isValid}
                styleTextInputContainer={styles.textInputName}
            />
            {/* {!responsiveStyles.isMobile ?
            <View style={styles.textInputName}>
            </View>
            : null} */}
            </View>
            <View style={[styles.textInputRowContainer]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('BirthDate', event.nativeEvent.layout.y);
                          onLayoutChange('DeathDate', event.nativeEvent.layout.y);
                        }
                      }}
            >
        <View style={styles.dateContainer}
            onLayout={(event) => {
                if(onLayoutChange){
                  console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                  console.log(event.nativeEvent);
                  onLayoutChange('BirthDate', event.nativeEvent.layout.y);
                }
              }}
            >
                <DateComponent
                onChangeFieldValue={(itemId, value) => {
                    if(editable){   
                        inputChangedHandler("BirthDate", value, true);
                    }
                }}
                editable={editable}
                itemId={0}
                initValue={inputs.BirthDate.value}
                item={{
                    fieldName: `${i18n.t('account.MyAccount_ManagePetProfileForm_BirthDate')} *`
                }}
                userSettingsState={userSettingsState}
                styleContainer={[styles.dateStyle, !responsiveStyles.isMobile ? { alignSelf: 'flex-start' } : null]}

            />
            

            {!inputs.BirthDate.isValid && (
                <ErrorComponentMessageError 
                    userSettingsState={userSettingsState} 
                    errorMessage={i18n.t('account.MyAccount_ManagePetProfileForm_BirthDate_ErrorMessage')}
                />
            )}
            </View>
            <View style={styles.dateContainer}
            onLayout={(event) => {
                if(onLayoutChange){
                  console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                  console.log(event.nativeEvent);
                  onLayoutChange('DeathDate', event.nativeEvent.layout.y);
                }
              }}
            >
                <DateComponent
                onChangeFieldValue={(itemId, value) => {
                    if(editable){   
                        inputChangedHandler("DeathDate", value, true);
                    }
                }}
                editable={editable}
                itemId={0}
                initValue={inputs.DeathDate.value}
                item={{
                    fieldName: `${i18n.t('account.MyAccount_ManagePetProfileForm_DeathDate')}`
                }}
                userSettingsState={userSettingsState}
                styleContainer={[styles.dateStyle, !responsiveStyles.isMobile ? { alignSelf: 'flex-start' } : null]}

            />
            </View>
            {/* {!responsiveStyles.isMobile ?
            <View style={styles.textInputName}>
            </View>
            : null} */}
            </View>
            <ManagePetProfileForm_PetTypeComponent
                userSettingsState={userSettingsState}
                inputs={inputs}
                inputChangedHandler={inputChangedHandler}
                editable={editable}
                textInputNameStyle={styles.textInputName}
                containerStyle={styles.paddingScreenInsideCustom}
                 />
                <ManagePetProfileForm_GenderComponent
                userSettingsState={userSettingsState}
                inputs={inputs}
                inputChangedHandler={inputChangedHandler}
                editable={editable}
                textInputNameStyle={styles.textInputName}
                containerStyle={styles.paddingScreenInsideCustom}
                 />
            <ManagePetProfileForm_Health_SpayedComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />
            <View style={[styles.NameContainer]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('PetType', event.nativeEvent.layout.y);
                          onLayoutChange('Gender', event.nativeEvent.layout.y);
                        }
                      }}
            >

            </View>
            
            

<View style={[styles.textInputRowContainer]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('Weight', event.nativeEvent.layout.y);
                          onLayoutChange('Address', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.Weight.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("Weight", value, true);
                    }
                }}
                editable={editable}
                isRequired={true}
                errorMessage={i18n.t('account.MyAccount_ManagePetProfileForm_Weight_ErrorMessage')}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_Weight')} *`}
                keyboardType={'numeric'}
                isValid={inputs.Weight.isValid}
                styleTextInputContainer={styles.textInputName}
            />
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.Address.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("Address", value, true);
                    }
                }}
                editable={editable}
                label={i18n.t('account.MyAccount_ManagePetProfileForm_Address')}
                styleTextInputContainer={styles.textInputName}
            />
            {/* {!responsiveStyles.isMobile ?
            <View style={styles.textInputName}>
            </View>
            : null} */}
            </View>
            <View style={[styles.NameContainer, styles.paddingScreenInsideCustom]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('Weight', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.Bio.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("Bio", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_Bio')}`}
                isValid={inputs.Bio.isValid}
                // styleTextInputContainer={styles.textInputName}
                styleTextInputContainer={[styles.inputStyleOneRow]}
                multiline={true}
                heightTextInput={responsiveStyles.screenHeight * 0.1 }
            />
            </View>

            <View style={[styles.textInputRowContainer]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('ContactName1', event.nativeEvent.layout.y);
                          onLayoutChange('ContactPhone1', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.ContactName1.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("ContactName1", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_ContactName')} 1*`}
                isRequired={true}
                errorMessage={i18n.t('account.MyAccount_ManagePetProfileForm_ContactName_ErrorMessage')}
                isValid={inputs.ContactName1.isValid}
                styleTextInputContainer={styles.textInputName}
                
            />
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.ContactPhone1.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("ContactPhone1", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_ContactPhone')} 1*`}
                isRequired={true}
                errorMessage={i18n.t('account.MyAccount_ManagePetProfileForm_ContactPhone_ErrorMessage')}
                isValid={inputs.ContactPhone1.isValid}
                keyboardType="numeric"
                styleTextInputContainer={styles.textInputName}
            />
            {/* {!responsiveStyles.isMobile ?
            <View style={styles.textInputName}>
            </View>
            : null} */}

            </View>
            <View style={[styles.textInputRowContainer]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('ContactName2', event.nativeEvent.layout.y);
                          onLayoutChange('ContactPhone2', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.ContactName2.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("ContactName2", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_ContactName')} 2`}
                isValid={inputs.ContactName2.isValid}
                styleTextInputContainer={styles.textInputName}
                
            />
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.ContactPhone2.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("ContactPhone2", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_ContactPhone')} 2`}
                isValid={inputs.ContactPhone2.isValid}
                keyboardType="numeric"
                styleTextInputContainer={styles.textInputName}
            />
            {/* {!responsiveStyles.isMobile ?
            <View style={styles.textInputName}>
            </View>
            : null} */}

            </View>

            <View style={[styles.NameContainer, styles.paddingScreenInsideCustom]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('Weight', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={inputs.AdditionalInfo.value}
                setValue={(value) => {
                    if(editable){   
                        inputChangedHandler("AdditionalInfo", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_AdditionalInfo')}`}
                isValid={inputs.AdditionalInfo.isValid}
                // styleTextInputContainer={styles.textInputName}
                styleTextInputContainer={[styles.inputStyleOneRow]}
                multiline={true}
                heightTextInput={responsiveStyles.screenHeight * 0.1 }
            />
            </View>
            </>
            : null}
            <ManagePetProfileForm_Header
            userSettingsState={userSettingsState}
            onPress={() => {
                setBehaviorVisible(!behaviorVisible);
            }}
            text={`${i18n.t('account.MyAccount_ManagePetProfileForm_Behavior')}`}
            visible={behaviorVisible}
            containerStyle={styles.paddingScreenInsideCustom}
            />
            {behaviorVisible ? 
            <>
            <ManagePetProfileForm_Behavior_DogsComponent
                userSettingsState={userSettingsState}
                inputs={additionalInfoInputs}
                inputChangedHandler={additionalInfoInputChangedHandler}
                editable={editable}
                textInputNameStyle={styles.textInputName}
                containerStyle={styles.paddingScreenInsideCustom}
                 />
            <ManagePetProfileForm_Behavior_CatsComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />
            <ManagePetProfileForm_Behavior_ChildrenComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />   
            <ManagePetProfileForm_Behavior_StragersComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />
            <ManagePetProfileForm_Behavior_OtherAnimalsComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />
            <ManagePetProfileForm_EnergyLevelComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
             />
            <ManagePetProfileForm_TrainedComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
             />
             <ManagePetProfileForm_NatureComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
             />


                 <View style={[styles.NameContainer, styles.paddingScreenInsideCustom]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('Behavior_AdditionalInfo', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={additionalInfoInputs.Behavior_AdditionalInfo.value}
                setValue={(value) => {
                    if(editable){   
                        additionalInfoInputChangedHandler("Behavior_AdditionalInfo", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_AdditionalInfo')}`}
                isValid={additionalInfoInputs.Behavior_AdditionalInfo.isValid}
                // styleTextInputContainer={styles.textInputName}
                styleTextInputContainer={[styles.inputStyleOneRow]}
                multiline={true}
                heightTextInput={responsiveStyles.screenHeight * 0.1 }
            />
            </View>
            </>
            : null}

            <ManagePetProfileForm_Header
            userSettingsState={userSettingsState}
            onPress={() => {
                setHealthVisible(!healthVisible);
            }}
            text={`${i18n.t('account.MyAccount_ManagePetProfileForm_Health')}`}
            visible={healthVisible}
            />


            {healthVisible ? 
            <>
            <ManagePetProfileForm_Health_AllergiesComponent
                userSettingsState={userSettingsState}
                inputs={additionalInfoInputs}
                inputChangedHandler={additionalInfoInputChangedHandler}
                editable={editable}
                textInputNameStyle={styles.textInputName}
                containerStyle={styles.paddingScreenInsideCustom}
                 />
            <ManagePetProfileForm_Health_MedicationComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />
            <ManagePetProfileForm_Health_VaccineComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />   
            <ManagePetProfileForm_Health_MedicalIndicationsComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />
                 <View style={[styles.NameContainer, styles.paddingScreenInsideCustom]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('Health_AdditionalInfo', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={additionalInfoInputs.Health_AdditionalInfo.value}
                setValue={(value) => {
                    if(editable){   
                        additionalInfoInputChangedHandler("Health_AdditionalInfo", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_AdditionalInfo')}`}
                isValid={additionalInfoInputs.Health_AdditionalInfo.isValid}
                // styleTextInputContainer={styles.textInputName}
                styleTextInputContainer={[styles.inputStyleOneRow]}
                multiline={true}
                heightTextInput={responsiveStyles.screenHeight * 0.1 }
            />
            </View>
            </>
            : null}

<ManagePetProfileForm_Header
            userSettingsState={userSettingsState}
            onPress={() => {
                setVetVisible(!vetVisible);
            }}
            text={`${i18n.t('account.MyAccount_ManagePetProfileForm_Vet')}`}
            visible={vetVisible}
            />


            {vetVisible ? 
            <>
            <ManagePetProfileForm_Vet_NameComponent
                userSettingsState={userSettingsState}
                inputs={additionalInfoInputs}
                inputChangedHandler={additionalInfoInputChangedHandler}
                editable={editable}
                textInputNameStyle={styles.textInputName}
                containerStyle={styles.paddingScreenInsideCustom}
                 />
            <ManagePetProfileForm_Vet_PhoneNumberComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />
            <ManagePetProfileForm_Vet_MicrochipNumberComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />   
            <ManagePetProfileForm_Vet_RabiesNumberComponent
            userSettingsState={userSettingsState}
            inputs={additionalInfoInputs}
            inputChangedHandler={additionalInfoInputChangedHandler}
            editable={editable}
            textInputNameStyle={styles.textInputName}
            containerStyle={styles.paddingScreenInsideCustom}
            />
                 <View style={[styles.NameContainer, styles.paddingScreenInsideCustom]}
                      onLayout={(event) => {
                        if(onLayoutChange){
                          console.log(`onLayout, onLayoutChange is ${onLayoutChange}`);
                          console.log(event.nativeEvent);
                          onLayoutChange('Vet_AdditionalInfo', event.nativeEvent.layout.y);
                        }
                      }}
            >
            <TextInputWithErrorComponent
                userSettingsState={userSettingsState}
                value={additionalInfoInputs.Vet_AdditionalInfo.value}
                setValue={(value) => {
                    if(editable){   
                        additionalInfoInputChangedHandler("Vet_AdditionalInfo", value, true);
                    }
                }}
                editable={editable}
                label={`${i18n.t('account.MyAccount_ManagePetProfileForm_AdditionalInfo')}`}
                isValid={additionalInfoInputs.Vet_AdditionalInfo.isValid}
                // styleTextInputContainer={styles.textInputName}
                styleTextInputContainer={[styles.inputStyleOneRow]}
                multiline={true}
                heightTextInput={responsiveStyles.screenHeight * 0.1 }
            />
            </View>
            </>
            : null}
            </View>

            <Text style={styles.text}>{i18n.t('account.MyAccount_ManageProfileForm_Example1')} {i18n.t('account.MyAccount_ManageProfileForm_Example2')}</Text>
            <Text style={[styles.text, { marginBottom: 20 }]}>{i18n.t('account.MyAccount_ManageProfileForm_Example3')}</Text>

            {editable ? 
        <>
            <View style={styles.buttonContainer}>
                <ButtonTypeButton
                userSettingsState={userSettingsState}
                    style={styles.button}
                    loading={isLoading}
                    onPress={async () => {
                        setIsLoading(true);
                        await submitHandler();
                        setIsLoading(false);
                    }}
                >
                    {i18n.t('account.MyAccount_ManagePetProfileForm_Save')}
                </ButtonTypeButton>
            </View>
            { Id && 
                <TouchableOpacity 
                    style={styles.deleteContainer}
                    onPress={() => setDeleteAlertVisible(true)}
                >
                    <Feather name="trash-2" size={36} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
                </TouchableOpacity>
            }
        </>
         : null }

        </View>
        </View>
    );
}

const styling = (responsiveStyles, paddingScreen, paddingScreenInsideCustom, isAdmin) => StyleSheet.create({
    container: {
        flex: 1,
        marginHorizontal: paddingScreen,
        // padding: 10,
      },
      mainContainer: {
        // backgroundColor: 'yellow',
      },
    text: {
        fontFamily: GlobalStyles.fonts.application_font,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
    },
    titleContainer: {
        paddingBottom: 20,
        marginVertical: !responsiveStyles.isMobile ? 20 : 10,
        borderBottomWidth: 1,
        borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
        marginHorizontal: paddingScreen,
    },
    title: {
        fontSize: responsiveStyles.fontSize_H1,
        fontWeight: '700',
        fontFamily: GlobalStyles.fonts.application_font,
        textAlign: 'center',
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    },
    NameContainer: {
        flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
        // justifyContent: !responsiveStyles.isMobile ?  'center' : null,
        justifyContent: !responsiveStyles.isMobile ?  'space-between' : null,
        // backgroundColor: 'blue',
    },
    textInputName: {
        width: !responsiveStyles.isMobile ? (responsiveStyles.screenWidth - paddingScreen) * 0.22 : null,
        // backgroundColor: isAdmin ? null : 'gray',
    },
    dateContainer: {
        // flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
        // justifyContent: !responsiveStyles.isMobile ?  'space-evenly' : null,
        marginHorizontal: !responsiveStyles.isMobile ? -10 : null,
    },
    dateStyle: {
        width: !responsiveStyles.isMobile ? (responsiveStyles.screenWidth - paddingScreen) * 0.22 : '100%',
        // backgroundColor: isAdmin ? null : 'gray',
        marginTop: 0,
    },

    buttonContainer: {
        marginBottom: 20,
        marginTop: 20,
        paddingTop: 20,
        alignItems: 'center',
    },
    button: {
        width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.8, // Use wp function here
        // width: responsiveStyles.screenWidth * 0.8,
    },
    userPhoto: {
        // width: !responsiveStyles.isMobile ? 200 : 150,
        // height: !responsiveStyles.isMobile ? 200 : 150,
        width: responsiveStyles.screenWidth > 1200 ? 200 : 150,
        height: responsiveStyles.screenWidth > 1200 ? 200 : 150,
        borderRadius: 300,
        // borderWidth: 4,
        borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
        marginBottom: 10,
    },
    imageContainer: {
        alignItems: 'center',
        // paddingTop: !responsiveStyles.isMobile ? 130 :  90,  // old

        // marginTop: responsiveStyles.screenWidth > 1200 ? 400 - 100 : !responsiveStyles.isWeb ?  150 - 70 : (responsiveStyles.screenWidth / 3) - 70,  // if backgroundImageContainer is outside styles.container
        marginTop: responsiveStyles.screenWidth > 1200 ? -100 : !responsiveStyles.isWeb ?  -50 : -70,
        marginHorizontal: paddingScreen,
    },
    backgroundImageContainer: {

        // position: 'absolute',  // if backgroundImageContainer is outside styles.container
        // top: !responsiveStyles.isMobile ? 120 : 150 - 60,  // if backgroundImageContainer is outside styles.container

        // top: !responsiveStyles.isMobile ? 120 : 70, // old
        // right: !responsiveStyles.isMobile ? 0 : null,
        
    },
    backgroundImage: {
        // height: responsiveStyles.screenWidth > 1200 ? 400 : responsiveStyles.screenWidth > 768 ? 350 : 150,
        // aspectRatio: !responsiveStyles.isMobile ? 288 / 100 : null,
        // width: !responsiveStyles.isMobile ? null : responsiveStyles.screenWidth,

        height: responsiveStyles.screenWidth > 1200 ? 400 : responsiveStyles.screenWidth / 3,
        minHeight: 150,
        width: responsiveStyles.screenWidth,
        maxWidth: 1200,
        // marginLeft: responsiveStyles.screenWidth > 1200 ? (responsiveStyles.screenWidth - 1200) / 2 : null, // if backgroundImageContainer is outside styles.container
        alignSelf: 'center',
        // backgroundColor:'rgba(0, 0, 0, 0.3)',

         // old
        // width: responsiveStyles.screenWidth,
        // height: !responsiveStyles.isMobile ? 200 : 150,
        // borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
        // // marginLeft: !responsiveStyles.isRTL ? -paddingScreen : null,
        // // marginRight: responsiveStyles.isRTL ? -paddingScreen : null,
        // marginLeft: -paddingScreen,
    },
    noteText: {
        paddingTop: !responsiveStyles.isMobile ? 20 : 20,
        paddingBottom: !responsiveStyles.isMobile ? 40 : 30,
        color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
        fontSize: responsiveStyles.fontSize_H5,
        fontFamily: GlobalStyles.fonts.application_font,
    },
    deleteContainer: {
        alignItems: 'center',
        paddingBottom: responsiveStyles.isWeb ? 20 : 0,
    },
    inputStyleOneRow: {
        width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 
        (responsiveStyles.screenWidth) * 0.22 * 2 
        : responsiveStyles.isTablet ? (responsiveStyles.screenWidth) * 0.3 * 2 
        : null,
      },
      textInputRowContainer: {
        flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
        justifyContent: !responsiveStyles.isMobile ?  'space-between' : null,
        // justifyContent: !responsiveStyles.isMobile ?  'space-evenly' : null,
        marginHorizontal: paddingScreenInsideCustom,
        // backgroundColor:'brown', 
      },
      paddingScreenInsideCustom: {
        marginHorizontal: paddingScreenInsideCustom,
      },
});

export default ManagePetProfileForm;
