import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import TutorialContainer from "./TutorialContainer";
import { getAsset } from "../../functions/getAsset";
import { navigateToScreen } from "../../navigationResponsive";

  const Step3TextComponent = ({ 
    userSettingsState,
    showOnlyFirst = false,
    navigation,
    isFromHome = false,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <TutorialContainer
    isSetVideoSlider={true}
    isOpposite={true}
    isFromHome={isFromHome}
    userSettingsState={userSettingsState}
    videoUri={getAsset(require('../../../assets/tutorial/step3.mp4'))}
    durationTime={"00:02:58.0000000"}
    title1={i18n.t('tutorial.Step3_title1')}
    title2={i18n.t('tutorial.Step3_title2')}
    showButton={showOnlyFirst}
    stylesContainer={isFromHome ? null : { paddingBottom: 50 }}
    onPress={() => {
      navigateToScreen(navigation, 'StoreFlow', `TutorialExplanation`, { Type: "Step3" }, "Step3");
    }}
    text={
    <View>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text1')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text2')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text3')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text4')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text5')}</Text>
    {
        showOnlyFirst ? 
        <Text style={styles.text}>...</Text>
        : <>
<Text style={styles.text}>{i18n.t('tutorial.Step3_text6')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text7')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text8')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text9')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text10')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text11')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text12')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text13')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text14')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text15')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text16')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text17')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text18')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text19')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text20')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text21')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text22')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text23')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text24')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text25')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text26')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text27')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text28')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text29')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text30')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text31')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text32')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text33')}</Text>
        <Text style={styles.text}>{i18n.t('tutorial.Step3_text34')}</Text>
        </>
    }
    </View>
            }
            />
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  text: {
    fontSize: responsiveStyles.fontSize_H5,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    maxWidth: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
    ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.48,
    marginHorizontal: 20,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
});

export default Step3TextComponent;