import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, FlatList, Dimensions, Image, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigate } from "../../navigationRef";
import { navigateToScreen } from "../../navigationResponsive";
import { getImageUrl } from "../../functions/getImageUrl";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const OrderItemsListDetailsComponent = ({ 
    userSettingsState, 
    data = [],
    navigation,
    isOrderDetails = false,
    isFromOrderDetails = false,
   }) => {
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles, isOrderDetails);

  return (
    <View style={styles.bottomCardContainer}>
      <FlatList
          scrollEnabled={false}
          data={data}
    key={(item) => item.Id.toString()} 
          renderItem={({ item, index }) =>
          {
            return <View style={styles.itemContainer}>
              <View style={styles.imageContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
    uri={getImageUrl(item.ImagePath)}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
  isTouchable={false}
    />
    </View>
    <View style={[styles.JewleryNameContainer, { alignItems: responsiveStyles.isRTL ? 'flex-end' : 'flex-start' }]}>
      <Text style={[styles.JewleryName]}>{item.JewleryName}</Text>
    </View>
    <View style={[styles.JewleryNameContainer, { alignItems: responsiveStyles.isRTL ? 'flex-start' : 'flex-end' }]}>
      <Text style={[styles.JewleryName]}>{item.Price} ₪</Text>
    </View>

    { 
       isOrderDetails ? 
       <View style={styles.buttonContainer}>
       <ButtonTypeButton
       userSettingsState={userSettingsState}
             style={styles.button}
             onPress={() => {
               navigateToScreen(navigation, 'AccountFlow', isFromOrderDetails ? `OrderDetails` : `JewleryDetails`, { Id: item.Id }, item.Id);
               // navigate('AccountFlow', { screen: 'OrderDetails', params:  { Id: item.Id } });
               // navigate("OrderDetails", { Id: item.Id });
             }}
             >
               {i18n.t('account.OrderDetailsScreen_OrderItemsListDetailsComponent_View')}
         </ButtonTypeButton>
       </View>
       : null
    }
    {/* {
      isOrderDetails ?
    <TouchableOpacity 
    style={styles.qrCodeContainer}
    onPress={() => {
      navigateToScreen(navigation, 'AccountFlow', `JewleryDetails`, { Id: item.Id }, item.Id);
    }}
    >
          <View style={styles.container}>
          <Text style={[styles.qrCodeName]}>
      {i18n.t('account.OrderDetailsScreen_OrderItemsListDetailsComponent_View')}
      </Text>
      <View style={styles.underline} />
    </View>
      
    </TouchableOpacity>
    : null } */}
  </View>
          }}
          />
  </View>

  );
}
const styling = (responsiveStyles, isOrderDetails) => StyleSheet.create({
  bottomCardContainer: {
  },
  itemContainer: {
    flex:1,
    padding: 15,
    // width: responsiveStyles.screenWidth * 0.8,
    // marginVertical: 10,
    
    alignItems: 'flex-end',
    flexDirection: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
    
    borderColor: GlobalStyles.colors.buttonBorderColor,
    // borderWidth: isOrderDetails ? 1 : null,
    borderBottomWidth: 1,
    // borderRadius: 10,

    // shadowOpacity: 0.5,
    // shadowColor: "gray",
    // shadowOffset: {
    //     width: 0,
    //     height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,

    // elevation: 5,
  },
  imageContainer: {
    alignSelf: 'center'
  },
  userPhoto: {
    // width: 50,
    // height: 50,
    width: !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.08 : 150,
    aspectRatio: 1 / 1,
    borderRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 25 : 15,
    borderWidth: 4,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // marginBottom: 10,
  },
  JewleryName: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    // width: responsiveStyles.screenWidth * 0.6,
    // paddingTop: 10,
    // fontWeight: '700',
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    textAlign: 'center',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  JewleryNameContainer: {
    flex:1,
    alignSelf:'center',
    paddingHorizontal: !responsiveStyles.isMobile ? 30 : null,
    paddingTop: !responsiveStyles.isMobile ? 10 : 10,
  },
  qrCodeContainer: {
    alignSelf:'center',
    // borderRadius: 100,
    // borderWidth: 1,
    padding: 10,
    // backgroundColor: GlobalStyles.colors.Lavender
  },
  qrCodeName: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold',
    // textDecorationLine: 'underline',

  },
  underline: {
    height: 2, // Thickness of the underline
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    marginTop: 0,
  },
  button: {
    width: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.1
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.18
     : responsiveStyles.screenWidth * 0.4,
    minWidth: !responsiveStyles.isMobile ? 70 : null,
    paddingTop:10,
},

buttonContainer: {
  alignSelf:'center',
  paddingRight: !responsiveStyles.isMobile ? !responsiveStyles.isRTL ? 20 : 10 : null,
  paddingLeft: !responsiveStyles.isMobile ? responsiveStyles.isRTL ? 20 : 10 : null,
},
});

export default OrderItemsListDetailsComponent;