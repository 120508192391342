import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Dimensions, ScrollView, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../../context/UserSettingsContext";
import { GlobalStyles } from '../../../constants/styles';
import Screen from "../../../components/Screen";
import i18n from '../../../hooks/useI18n';
import { GenericAlert } from '../../../components/alert/GenericAlert';
import ImageProfile from '../../../components/images/ImageProfile';
import BuisnessFooter from '../../../components/store/BuisnessFooter';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import BulletText from '../../../components/inputs/BulletText';
import useResponsiveStyles from '../../../functions/ResponsiveStyles';

const TermsAndConditionsScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Terms And Conditions";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    <ScrollView style={[styles.container]}>
    <View style={styles.subContainer}>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>
      <View style={{padding: 10}}>
      
      <View style={styles.TitleContainer}>
        <Text style={[styles.title,styles.mainTitle]}>
        {i18n.t('terms.Title')}
          </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('terms.description')}</Text>
      </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section1_Title')}</Text>
        </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('terms.Section1_1')}</Text>
      </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section2_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section2_1_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section2_1_2')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section2_2_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section2_2_2')}</Text>
        </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section3_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section3_1_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section3_1_2')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section3_2_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section3_2_2')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section3_3_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section3_3_2')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('terms.Section3_3_reason_1')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('terms.Section3_3_reason_2')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('terms.Section3_3_reason_3')} />



      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section4_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section4_1_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section4_1_2')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section4_2_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section4_2_2')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section4_3_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section4_3_2')}</Text>
        </Text>
      </View>

      
      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section5_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section5_1_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section5_1_2')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section5_2_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section5_2_2')}</Text>
        </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section6_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section6_1_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section6_1_2')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section6_2_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section6_2_2')}</Text>
        </Text>
      </View>



      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section7_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section7_1_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section7_1_2')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textSubBold]}>{i18n.t('terms.Section7_2_1')}</Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section7_2_2')}</Text>
        </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section8_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section8_1')}</Text>
        </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section9_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section9_1')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={i18n.t('terms.Section9_1_reason_1')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('terms.Section9_1_reason_2')} />
      <BulletText userSettingsState={userSettingsState} text={i18n.t('terms.Section9_1_reason_3')} />



      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section10_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section10_1')}</Text>
        </Text>
      </View>


      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section11_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section11_1')}</Text>
        </Text>
      </View>



      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text,styles.textBold, styles.title]}>{i18n.t('terms.Section12_Title')}</Text>
        </Text>
      </View>

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={[styles.text]}>{i18n.t('terms.Section12_1')}</Text>
        </Text>
      </View>

      <BulletText userSettingsState={userSettingsState} text={<>
        <Text style={[styles.text,styles.textBold]}>{i18n.t('terms.ContactUs_Email')} </Text>
        <Text style={styles.text}>{i18n.t('footer.Email')} </Text>
        </>} />

        <BulletText userSettingsState={userSettingsState} text={<>
          <Text style={[styles.text,styles.textBold]}>{i18n.t('terms.ContactUs_Phone')} </Text>
          <Text style={styles.text}>{i18n.t('footer.Phone')} </Text>
        </>} />

      <View style={styles.DescriptionContainer}>
      <Text>
        <Text style={styles.text}>{i18n.t('terms.FinalWords')} </Text>
        </Text>
      </View>

      </View>
      </View>
      <BuisnessFooter userSettingsState={userSettingsState} navigation={navigation} />
    </ScrollView>
    </Screen>

}


TermsAndConditionsScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  subContainer: {
    flex: 1,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.5 : null,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  TitleContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
  },
  mainTitle: {
    fontSize: responsiveStyles.fontSize_H1,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '900',
  },
  title: {
    fontSize: responsiveStyles.fontSize_H3,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
  },
  DescriptionContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    paddingVertical: 5,
  },
  secondPharagraph: {
    paddingTop: 20,
  },
  text: {
    textAlign:'center',
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    
},
  textBold: {
    fontWeight: '900',
  },
  textSubBold: {
    fontWeight: '700',
  },
});

export default TermsAndConditionsScreen;
