export default {
    MyAccount_Title: "My Account",
    MyAccount_Name: "Name:",
    MyAccount_Email: "Email:",
    MyAccount_Joined: "Joined:",
    MyAccount_BottomCard_Posts: "Posts",
    MyAccount_BottomCard_Jewlery: "Jewlery",
    MyAccount_BottomCard_Profiles: `Profiles`,
    MyAccount_BottomCard_Account: "Account",
    MyAccount_AccountForm_Title: "Settings",
    MyAccount_AccountForm_ChangePassword: "Change password",
    MyAccount_AccountForm_PersonalDetails: "Personal Details",
    MyAccount_AccountForm_FirstName: "First Name",
    MyAccount_AccountForm_FirstName_ErrorMessage: "Must enter first name",
    MyAccount_AccountForm_LastName: "Last Name",
    MyAccount_AccountForm_LastName_ErrorMessage: "Must enter last name",
    MyAccount_AccountForm_LocationDetails: "Location Details",
    MyAccount_AccountForm_State: "State",
    MyAccount_AccountForm_State_ErrorMessage: "Must enter state",
    MyAccount_AccountForm_City: "City",
    MyAccount_AccountForm_City_ErrorMessage: "Must enter city",
    MyAccount_AccountForm_Save: "Save Changes",
    MyAccount_TributeDisplayTypeComponent_Tributes: "Tributes",
    MyAccount_TributeDisplayTypeComponent_Invitations: "Invitations",
    MyAccount_ProfileListDisplayTypeComponent_Human: "Human",
    MyAccount_ProfileListDisplayTypeComponent_Pets: "Pets",
    MyAccount_ProfileDisplayTypeComponent_Profiles: "Profiles",
    MyAccount_ProfileDisplayTypeComponent_AuthorizedProfiles: "Authorized Profiles",
    MyAccount_ProfileDisplayTypeComponent_Archived: "Archived",
    MyAccount_JewleryDisplayTypeComponent_Jewlery: "Jewlery",
    MyAccount_JewleryDisplayTypeComponent_Orders: "Orders",
    MyAccount_JewleryListComponent_Profile: "Profile:",
    MyAccount_JewleryListComponent_NotePets: "Here you can create a profile for your pet, this is the place to upload photos, tributes, videos and audio files.",
    MyAccount_JewleryListComponent_NoteHuman: "Here you can create a profile for the person who will receive your wonderful gift you purchased, this is the place to upload photos, tributes, videos and audio files.",
    MyAccount_JewleryListComponent_QRCODE: "QR CODE",
    MyAccount_JewleryListComponent_View: "View jewelry",
    MyAccount_ProfileListComponent_Create: "Create new profile",
    MyAccount_ProfileListComponent_Bonding: "Bonding:",
    MyAccount_ProfileListComponent_Archived: "Archived",
    MyAccount_ProfileListComponent_ArchivedDate: "Archived",
    MyAccount_ProfileListComponent_Restore: "Restore",
    MyAccount_ProfileListComponent_View: "View",
    MyAccount_ProfileListComponent_Edit: "Edit",
    MyAccount_JewleryComponent_Created: "Created in",
    UNVERIFIED_UserProfile: "THIS PROFILE HAS NOT BEEN ASSIGNED TO A JEWELRY YET",
    MyAccount_JewleryComponent_JewleryAssigned1: "This profile has",
    MyAccount_JewleryComponent_JewleryAssigned2: "jewlery assigned",
    MyAccount_OrderListComponent_OrderNumber: "order number:",
    MyAccount_OrderListComponent_Items: "items",
    MyAccount_OrderListComponent_OrderDate: "Order date:",
    MyAccount_OrderListComponent_Total: "Total:",
    MyAccount_OrderListComponent_View: "View order",
    MyAccount_ManageProfileForm_Create: "Create",
    MyAccount_ManageProfileForm_Edit: "Edit",
    buttonmessage1DeleteAlert: `Are you sure you want to delete this profile?
Please note that if you click 'Yes,' all connections to the assigned jewelry will be deleted.`,
    buttonmessage1Yes: "Yes",
    buttonmessage2No: "No",

    MyAccount_ManagePetProfileForm_BasicInformation: "Basic information",
    MyAccount_ManagePetProfileForm_Behavior: "Behavior",
    MyAccount_ManagePetProfileForm_Health: "Health",
    MyAccount_ManagePetProfileForm_Vet: "Vet",
    MyAccount_ManagePetProfileForm_PetName: "Pet name",
    MyAccount_ManagePetProfileForm_PetName_ErrorMessage: "Must enter pet name",
    MyAccount_ManagePetProfileForm_PetType: "Pet type",
    MyAccount_ManagePetProfileForm_PetType_ErrorMessage: "Must choose pet type",
    MyAccount_ManagePetProfileForm_PetType_Dog: "Dog",
    MyAccount_ManagePetProfileForm_PetType_Cat: "Cat",
    MyAccount_ManagePetProfileForm_PetType_Other: "Other",
    MyAccount_ManagePetProfileForm_Gender: "Gender",
    MyAccount_ManagePetProfileForm_Gender_ErrorMessage: "Must choose gender",
    MyAccount_ManagePetProfileForm_Gender_Boy: "Boy",
    MyAccount_ManagePetProfileForm_Gender_Girl: "Girl",
    MyAccount_ManagePetProfileForm_Breed: "Breed",
    MyAccount_ManagePetProfileForm_BirthDate: "Birth date",
    MyAccount_ManagePetProfileForm_BirthDate_ErrorMessage: "Must enter birth date",
    MyAccount_ManagePetProfileForm_DeathDate: "Death date",
    MyAccount_ManagePetProfileForm_Weight: "Weight",
    MyAccount_ManagePetProfileForm_Weight_ErrorMessage: "Must enter weight",
    MyAccount_ManagePetProfileForm_Bio: "Bio",
    MyAccount_ManagePetProfileForm_ContactName: "Contact name",
    MyAccount_ManagePetProfileForm_ContactName_ErrorMessage: "Must enter contact name",
    MyAccount_ManagePetProfileForm_ContactPhone: "Contact phone",
    MyAccount_ManagePetProfileForm_ContactPhone_ErrorMessage: "Must enter contact phone",
    MyAccount_ManagePetProfileForm_Address: "Address",
    MyAccount_ManagePetProfileForm_AdditionalInfo: "Additional Information",
    MyAccount_ManagePetProfileForm_Behavior_GoodWith: "Good with",
    MyAccount_ManagePetProfileForm_Behavior_Dogs: "dogs",
    MyAccount_ManagePetProfileForm_Yes: "Yes",
    MyAccount_ManagePetProfileForm_No: "No",
    MyAccount_ManagePetProfileForm_DependsOnSomeCases: "Depends on some cases",
    MyAccount_ManagePetProfileForm_Behavior_Cats: "cats",
    MyAccount_ManagePetProfileForm_Behavior_Children: "children",
    MyAccount_ManagePetProfileForm_Behavior_Strangers: "strangers",
    MyAccount_ManagePetProfileForm_Behavior_OtherAnimals: "Other Animals",
    MyAccount_ManagePetProfileForm_Behavior_EnergyLevel: "Energy Level",
    MyAccount_ManagePetProfileForm_Behavior_EnergyLevel_Low: "Low",
    MyAccount_ManagePetProfileForm_Behavior_EnergyLevel_Medium: "Medium",
    MyAccount_ManagePetProfileForm_Behavior_EnergyLevel_High: "High",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel: "Trained level",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_Untrained: "Untrained",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_Basic: "Basic training",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_Trained: "Fully trained",
    MyAccount_ManagePetProfileForm_Behavior_TrainedLevel_InTraining: "In training",
    MyAccount_ManagePetProfileForm_Behavior_Nature: "Nature",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Shy: "Shy",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Social: "Social",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Aggressive: "Aggressive",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Curious: "Curious",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Familiar: "Familiar with Known Places",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Protective: "Protective",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Energetic: "Energetic",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Dominant: "Dominant",
    MyAccount_ManagePetProfileForm_Behavior_Nature_Sensitive: "Sensitive",
    MyAccount_ManagePetProfileForm_Health_Allergies: "Allergies",
    MyAccount_ManagePetProfileForm_Health_Medication: "Medication",
    MyAccount_ManagePetProfileForm_Health_Vaccine: "Vaccine",
    MyAccount_ManagePetProfileForm_Health_MedicalIndications: "Medical Indications",
    MyAccount_ManagePetProfileForm_Health_Spayed: "Spayed/neutered",
    MyAccount_ManagePetProfileForm_Health_AdditionalInfo: "Additional Information",
    MyAccount_ManagePetProfileForm_Vet_Name: "Name",
    MyAccount_ManagePetProfileForm_Vet_PhoneNumber: "Phone number",
    MyAccount_ManagePetProfileForm_Vet_MicrochipNumber: "Microchip number",
    MyAccount_ManagePetProfileForm_Vet_RabiesNumber: "Rabies Number",
    MyAccount_ManagePetProfileForm_Vet_AdditionalInfo: "Additional Information",
    MyAccount_ManagePetProfileForm_Save: `Save changes`,
    
    MyAccount_ManageProfileForm_NewProfile: "profile",
    MyAccount_ManageProfileForm_FirstName: "First Name",
    MyAccount_ManageProfileForm_FirstName_ErrorMessage: "Must enter first name",
    MyAccount_ManageProfileForm_MiddleName: "Middle Name",
    MyAccount_ManageProfileForm_MiddleName_ErrorMessage: "Must enter middle name",
    MyAccount_ManageProfileForm_LastName: "Last Name",
    MyAccount_ManageProfileForm_LastName_ErrorMessage: "Must enter last name",
    MyAccount_ManageProfileForm_NoteText: "In the screen you can fill the details of the person you purchased the gift for",
    MyAccount_ManageProfileForm_NoteForNonAdmin: "You cannot edit this user's bio, only the admin can",
    MyAccount_ManageProfileForm_Relationship: "Relationship:",
    MyAccount_ManageProfileForm_Relationship_ErrorMessage: "Must select relationship:",
    MyAccount_ManageProfileForm_RelationshipOptions: "Select an option",
    MyAccount_ManageProfileForm_Lifetime: "Lifetime",
    MyAccount_ManageProfileForm_BirthDate: "BirthDate",
    MyAccount_ManageProfileForm_BirthDate_ErrorMessage: "Must enter birth date",
    MyAccount_ManageProfileForm_DeathDate: "DeathDate",
    MyAccount_ManageProfileForm_LocationDetails: "Location Details",
    MyAccount_ManageProfileForm_State: "State",
    MyAccount_ManageProfileForm_City: "City",
    MyAccount_ManageProfileForm_Quote: "Quote Section",
    MyAccount_ManageProfileForm_QuoteText: "Text or phase",
    MyAccount_ManageProfileForm_Example1: "example:",
    MyAccount_ManageProfileForm_Example2: `"never let the fear of striking out keep you from playing the game." - Babe ruth`,
    MyAccount_ManageProfileForm_Example3: `* This headline text is the one that shows above the name of the person.`,
    MyAccount_ManageProfileForm_Save: `Save changes`,
    MyAccount_EditProfile_DisplayTypeComponent_Bio: "Bio",
    MyAccount_EditProfile_DisplayTypeComponent_Media: "Media",
    MyAccount_EditProfile_DisplayTypeComponent_Tributes: "Tributes",
    MyAccount_EditProfile_DisplayTypeComponent_Admins: "Admins",
    MyAccount_EditProfile_ManageMediaComponent_Create: "Add new file",
    MyAccount_AdminsComponent_InviteUser: "Invite user",
    MyAccount_AdminsComponent_DisplayTypeComponent_ProfileAdmins: "Profile Admins",
    MyAccount_AdminsComponent_DisplayTypeComponent_PendingInvitations: "Pending Invitations",
    MyAccount_AdminsComponent_Joined: "Joined:",
    MyAccount_AdminsComponent_Admin: "Admin",
    MyAccount_AdminsComponent_Deactivate: "Deactivate",
    MyAccount_InviteUserAdminAlert_Title: "Invite registered user for managing",
    MyAccount_InviteUserAdminAlert_Username: "Username",
    MyAccount_InviteUserAdminAlert_Username_ErrorMessage: "Must enter category username",
    MyAccount_InviteUserAdminAlert_SubmitButton: "Send Invintation",
    MyAccount_TributeEmptyListComponent_Title: "No tributes posted yet",
    MyAccount_TributeEmptyListComponent_SubTitle: "No tributes have been posted yet, share the first one here and start sharing.",
    MyAccount_TributeEmptyListComponent_PostButton: "Post a tribute",
    MyAccount_MediaEmptyListComponent_Title: "No media found",
    MyAccount_MediaEmptyListComponent_SubTitle1: "No media has been added yet in",
    MyAccount_MediaEmptyListComponent_SubTitle2: "'s collection.",
    MyAccount_MediaEmptyListComponent_PostButton: "Post a media",
    AssignJewleryToProfileComponent_Title: "You have not created a profile yet",
    AssignJewleryToProfileComponent_SubTitle1: "Click",
    AssignJewleryToProfileComponent_SubTitle2: "here",
    AssignJewleryToProfileComponent_SubTitle3: "to create a profile",
    MyAccount_JewelryEmptyListComponent_Title: "You have not purchased any product",
    MyAccount_JewelryEmptyListComponent_SubTitle1: "Click",
    MyAccount_JewelryEmptyListComponent_SubTitle2: "here",
    MyAccount_JewelryEmptyListComponent_SubTitle3: "to purchase",
    MyAccount_OrderEmptyListComponent_Title: "You have not purchased any product",
    MyAccount_OrderEmptyListComponent_SubTitle1: "Click",
    MyAccount_OrderEmptyListComponent_SubTitle2: "here",
    MyAccount_OrderEmptyListComponent_SubTitle3: "to purchase",
    MyAccount_AdminEmptyListComponent_Title: "No pending invitations",
    MyAccount_AdminEmptyListComponent_SubTitle1: "There are no pending invitations to be",
    MyAccount_AdminEmptyListComponent_SubTitle2: "'s admin.    Invite users to manage this profile and share meaningful recordings to contribute to this visual legacy.",
    MyAccount_CommentSectionListComponent_Title: "This post has no comments yet!",
    MyAccount_CommentSectionListComponent_SubTitle: "Be the first to share your opinion and leave a comment below.",
    MyAccount_ManageMediaPost_Edit: "Edit",
    MyAccount_ManageMediaPost_New: "New",
    MyAccount_ManageMediaPost_Post: "Post",
    MyAccount_ManageMediaPost_Title: "Title",
    MyAccount_ManageMediaPost_Title_ErrorMessage: "Must enter title",
    MyAccount_ManageMediaPost_Files_ErrorMessage: "Must upload atlease one file",
    MyAccount_ManageMediaPost_Description: "Description",
    MyAccount_ManageMediaPost_TypeOfContent: "Type of content:",
    MyAccount_ManageMediaPost_Photos: "Photos/Videos",
    MyAccount_ManageMediaPost_Audio: "Audio",
    MyAccount_ManageMediaPost_UploadTitle: "Upload photos/videos",
    MyAccount_ManageMediaPost_UploadAudio: "Upload audio files",
    MyAccount_ManageMediaPost_Save: "Save changes",
    MyAccount_ManageMediaPost_Cancel: "Cancel",
    MyAccount_ManageMediaPost_MaxMB: "you cannot upload files over the size of 25 MB",
    MyAccount_ManageTributePost_Edit: "Edit",
    MyAccount_ManageTributePost_New: "New",
    MyAccount_ManageTributePost_Post: "Tribute",
    MyAccount_ManageTributePost_UserName: "UserName",
    MyAccount_ManageTributePost_UserName_ErrorMessage: "Must enter UserName",
    MyAccount_ManageTributePost_Title: "Title",
    MyAccount_ManageTributePost_Title_ErrorMessage: "Must enter title",
    MyAccount_ManageTributePost_Description: "Description",
    MyAccount_ManageTributePost_Description_ErrorMessage: "Must enter description",
    MyAccount_ManageTributePost_TypeOfContent: "Type of content:",
    MyAccount_ManageTributePost_Photos: "Photos/Videos",
    MyAccount_ManageTributePost_Audio: "Audio",
    MyAccount_ManageTributePost_UploadTitle: "Upload photos/videos",
    MyAccount_ManageTributePost_UploadAudio: "Upload audio files",
    MyAccount_ManageTributePost_Save: "Save changes",
    MyAccount_ManageTributePost_Cancel: "Cancel",
    MyAccount_ManagePost_note1: "Please note that each tribute post has a maximum file size limit of 25 MB. You can submit files in JPEG, JPG, PNG, MP4, or MP3 formats. Ensure your submission does not exceed this limit.",
    MyAccount_ManagePost_note2: "Please note: The upload process may take up to a minute while we save your files. Thank you for your patience.",
    MyAccount_LanguageText: 'Language',
    MyAccount_DarkModeText: 'Dark Mode',
    OrderDetailsScreen_Title: 'Order Details',
    OrderDetailsScreen_OrderNumber: 'Order number',
    OrderDetailsScreen_OrderDate: 'Order date',
    OrderDetailsScreen_Total: 'Total',
    OrderDetailsScreen_OrderItemsListDetailsComponent_View: 'View jewelry',
    JewleryDetailsScreen_Title: 'Jewlery Details',
    JewleryDetailsScreen_QRCode: 'QR CODE',
    JewleryDetailsScreen_ShowProfileButton: 'Show profile',
    JewleryDetailsScreen_Profile: 'Profile',
    JewleryDetailsScreen_AssignProfileButton: 'Assign profile',
    AssignJewleryToProfileScreen_Title : `Assign jewlery`,
    AssignJewleryToProfileScreen_JewleryName : `Jewlery name`,
    AssignJewleryToProfileScreen_SelectedProfile : `Selected profile`,
    AssignJewleryToProfileScreen_AssignToProfile: `Assign to profile`,
    AssignJewleryToProfileScreen_Cancel : `Cancel`,
    LoginModalAlert_headerMessage: `To make a comment, please login or create an account`,
    LoginModalAlert_SubmitButton: `Comment Now!`,
    BirthDateComponent_RequestAdmin: `request editing permissions`,
    BirthDateComponent_CancelRequestAdmin: `Cancel editing permissions request`,
    BirthDateComponent_EditProfile: `Edit profile`,
    BirthDateComponent_ShareLocation: `Share location`,
    PetContainersComponent_WeightType: "Kg",
    PetContainersComponent_Weight: "Weight",
    PetContainersComponent_Years: "Years",
    PetContainersComponent_Age: "Age",
    PetContainersComponent_Gender: "Gender",
    ProfileScreen_BottomCardComponent_Bio: "Bio",
    ProfileScreen_BottomCardComponent_Media: "Media",
    ProfileScreen_BottomCardComponent_Tributes: "Tributes",
    MyAccount_: "",
    JewelryNotAssignedComponent_title: `Jewelry Not Assigned To A User`,
    JewelryNotAssignedComponent_subtitle1: `This jewelry is not currently assigned to a profile.`,
    JewelryNotAssignedComponent_subtitle2: `Only the user who purchased the jewelry can assign it to a profile.`,
    JewelryNotAssignedComponent_subtitle3: `To assign a user profile, please navigate to the "My Account" screen.`,
  };