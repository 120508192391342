import React, { useContext, useEffect, useRef, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, Dimensions, Platform, useWindowDimensions, Keyboard, KeyboardAvoidingView } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { shippingAmount, shippingInternational, Context as ShopContext } from "../../context/ShopContext";
import { GlobalStyles, Gold } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import TextInputWithErrorComponent from '../../components/forms/TextInputWithErrorComponent';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import CartBreadcrumbs from '../../components/store/CartBreadcrumbs';
import OrderSummeryComponent from '../../components/store/OrderSummeryComponent';
import { navigateToScreen } from '../../navigationResponsive';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import { TouchableWithoutFeedback } from 'react-native';
const CartInformationScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const { state: shopState, updateShippingDetails } = useContext(ShopContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    const scrollViewRef = useRef(null);
    const [inputs, setInputs] = useState({
      Country: {
        value: shopState.shippingDetails.Country,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      FirstName: {
        value: shopState.shippingDetails.FirstName,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      LastName: {
        value: shopState.shippingDetails.LastName,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Address: {
        value: shopState.shippingDetails.Address,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Apartment: {
        value: shopState.shippingDetails.Apartment,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      PostalCode: {
        value: shopState.shippingDetails.PostalCode,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      City: {
        value: shopState.shippingDetails.City,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      Phone: {
        value: shopState.shippingDetails.Phone,
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
    });

    const onLayoutChange = (inputName, y) => {

      setInputs(curInputs => ({
        ...curInputs,
        [inputName]: { ...curInputs[inputName], layoutY: y },
      }));
    };
    function inputChangedHandler(inputIdentifier, enteredValue,isChanged) {
      setInputs((curInputs) => {
        const newInputs = {
          ...curInputs,
          [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
        };
        return newInputs;
      });
      return;
    }

    const saveInfoHandler = async () => {
      const CountryIsValid = inputs.Country.value.toString().trim().length > 0;
      const FirstNameIsValid = inputs.FirstName.value.toString().trim().length > 0;
      const LastNameIsValid = inputs.LastName.value.toString().trim().length > 0;
      const AddressIsValid = inputs.Address.value.toString().trim().length > 0;
      const CityIsValid = inputs.City.value.toString().trim().length > 0;

      if (!CountryIsValid || !FirstNameIsValid || !LastNameIsValid || !AddressIsValid || !CityIsValid) {
        setInputs((curInputs) => {
          const updatedInputs = { ...curInputs };
          updatedInputs.Country.isValid = CountryIsValid;
          updatedInputs.FirstName.isValid = FirstNameIsValid;
          updatedInputs.LastName.isValid = LastNameIsValid;
          updatedInputs.Address.isValid = AddressIsValid;
          updatedInputs.City.isValid = CityIsValid;
          return updatedInputs;
        });

        if (!FirstNameIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.FirstName.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.FirstName.layoutY, x: inputs.FirstName.layoutY, animated: true });
          }
        }
        else if (!LastNameIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.LastName.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.LastName.layoutY, x: inputs.LastName.layoutY, animated: true });
          }
        }
        else if (!CountryIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.Country.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.Country.layoutY, x: inputs.Country.layoutY, animated: true });
          }
        }
        else if (!CityIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.City.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.City.layoutY, x: inputs.City.layoutY, animated: true });
          }
        }
        else if (!AddressIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.Address.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.Address.layoutY, x: inputs.Address.layoutY, animated: true });
          }
        }

        return;
      }

      let DataToSend = {
        Country: inputs.Country.value,
        FirstName: inputs.FirstName.value,
        LastName: inputs.LastName.value,
        Address: inputs.Address.value,
        Apartment: inputs.Apartment.value,
        PostalCode: inputs.PostalCode.value,
        City: inputs.City.value,
        Phone: inputs.Phone.value,
    }

      await updateShippingDetails(DataToSend);
      if(userSettingsState?.userSettings?.UserId){
        navigateToScreen(navigation, 'StoreFlow', `CartPayment`, null, null)
        // navigateToScreen(navigation, 'StoreFlow', `CartShippingInformation`, null, null)
      }
      else{
        
        setGenericAlertVisible(true);
      }
    };
    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Cart Information";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
    <KeyboardAvoidingView
      behavior={'padding'}
      style={styles.container}
      >
  <ScrollView 
  ref={scrollViewRef}
      contentContainerStyle={styles.scrollViewContent}
      keyboardShouldPersistTaps='handled'
  >
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <>
      <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={i18n.t('store.CartInformationScreen_LoginRequired_bodymessge')}
      buttonmessage1={i18n.t('store.CartInformationScreen_LoginRequired_buttonmessage1')}
      buttonmessage1onPress={() => {
        navigateToScreen(navigation, 'LoginFlow', `Signin`, null, null)
        // navigation.navigate('LoginFlow', { screen: 'Signin' });
        // navigation.navigate("loginFlow");
      }}
      buttonmessage2={i18n.t('store.CartInformationScreen_LoginRequired_buttonmessage2')}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      </View>
      <View style={styles.subContainer}>

      
      {/* <OrderSummeryComponent
  userSettingsState={userSettingsState}
  navigation={navigation}
  displayAsFinalProduct={true}
      /> */}
      <CartBreadcrumbs 
  userSettingsState={userSettingsState}
      navigation={navigation}
      showInformation={true}
      />
      <View style={{padding: 20}}>

      <View style={styles.mainContainer}>
      <View style={styles.textInputContainer}>
      <Text style={styles.title}>{i18n.t('store.CartInformationScreen_Title')}</Text>
      <View style={styles.textInputRowContainer}
      onLayout={(event) => {
        onLayoutChange('FirstName', event.nativeEvent.layout.y);
        onLayoutChange('LastName', event.nativeEvent.layout.y);
    }}
      >
      <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.FirstName.value}
    setValue={(value) => {
        inputChangedHandler("FirstName", value, true);
    }}
    label={`${i18n.t('store.CartInformationScreen_Inputs_FirstName')}*`}
    errorMessage={`${i18n.t('store.CartInformationScreen_Inputs_FirstName_ErrorMessage')}`}
    isRequired={true}
    isValid={inputs.FirstName.isValid}
    styleContainer={styles.textInputWithErrorComponentstyleContainer}
    styleTextInputContainer={styles.inputStyle}
    />
   <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.LastName.value}
    setValue={(value) => {
        inputChangedHandler("LastName", value, true);
    }}
    label={`${i18n.t('store.CartInformationScreen_Inputs_LastName')}*`}
    errorMessage={`${i18n.t('store.CartInformationScreen_Inputs_LastName_ErrorMessage')}`}
    isRequired={true}
    isValid={inputs.LastName.isValid}
    styleContainer={styles.textInputWithErrorComponentstyleContainer}
    styleTextInputContainer={styles.inputStyle}
    />
        </View>
        <View style={styles.textInputRowContainer}
        onLayout={(event) => {
          onLayoutChange('Phone', event.nativeEvent.layout.y);
          onLayoutChange('Country', event.nativeEvent.layout.y);
      }}
        >
        <TextInputWithErrorComponent
            userSettingsState={userSettingsState}
            value={inputs.Phone.value}
            setValue={(value) => {
                inputChangedHandler("Phone", value, true);
            }}
            label={`${i18n.t('store.CartInformationScreen_Inputs_Phone')}`}
            styleContainer={styles.textInputWithErrorComponentstyleContainer}
            styleTextInputContainer={styles.inputStyle}
            />
            <TextInputWithErrorComponent
            userSettingsState={userSettingsState}
            value={inputs.Country.value}
            setValue={(value) => {
                inputChangedHandler("Country", value, true);
            }}
            label={`${i18n.t('store.CartInformationScreen_Inputs_Country')}*`}
            errorMessage={`${i18n.t('store.CartInformationScreen_Inputs_Country_ErrorMessage')}`}
            isRequired={true}
            isValid={inputs.Country.isValid}
            styleContainer={styles.textInputWithErrorComponentstyleContainer}
            styleTextInputContainer={styles.inputStyle}
            />
        </View>
        <View style={styles.textInputRowContainer}
        onLayout={(event) => {
          onLayoutChange('City', event.nativeEvent.layout.y);
          onLayoutChange('Address', event.nativeEvent.layout.y);
      }}
      >
                  <TextInputWithErrorComponent
            userSettingsState={userSettingsState}
            value={inputs.City.value}
            setValue={(value) => {
                inputChangedHandler("City", value, true);
            }}
            label={`${i18n.t('store.CartInformationScreen_Inputs_City')}*`}
            errorMessage={`${i18n.t('store.CartInformationScreen_Inputs_City_ErrorMessage')}`}
            isRequired={true}
            isValid={inputs.City.isValid}
            styleContainer={styles.textInputWithErrorComponentstyleContainer}
            styleTextInputContainer={styles.inputStyle}
            />
            <TextInputWithErrorComponent
            userSettingsState={userSettingsState}
            value={inputs.Address.value}
            setValue={(value) => {
                inputChangedHandler("Address", value, true);
            }}
            label={`${i18n.t('store.CartInformationScreen_Inputs_Address')}*`}
            errorMessage={`${i18n.t('store.CartInformationScreen_Inputs_Address_ErrorMessage')}`}
            isRequired={true}
            isValid={inputs.Address.isValid}
            styleContainer={styles.textInputWithErrorComponentstyleContainer}
            styleTextInputContainer={styles.inputStyle}
            />

            </View>
            <View style={styles.textInputRowContainer}
            onLayout={(event) => {
              onLayoutChange('Apartment', event.nativeEvent.layout.y);
              onLayoutChange('PostalCode', event.nativeEvent.layout.y);
          }}
            >
                <TextInputWithErrorComponent
            userSettingsState={userSettingsState}
            value={inputs.Apartment.value}
            setValue={(value) => {
                inputChangedHandler("Apartment", value, true);
            }}
            label={`${i18n.t('store.CartInformationScreen_Inputs_Apartment')}`}
            styleContainer={styles.textInputWithErrorComponentstyleContainer}
            styleTextInputContainer={styles.inputStyle}
            />

            <TextInputWithErrorComponent
            userSettingsState={userSettingsState}
            value={inputs.PostalCode.value}
            setValue={(value) => {
                inputChangedHandler("PostalCode", value, true);
            }}
            label={`${i18n.t('store.CartInformationScreen_Inputs_PostalCode')}`}
            styleContainer={[styles.textInputWithErrorComponentstyleContainer, responsiveStyles.isDesktop || responsiveStyles.isLaptop ? {marginLeft:  responsiveStyles.screenWidth * 0.015} : null]}
            styleTextInputContainer={styles.inputStyle}
            />

            </View>
      <View style={{padding: 20}}>
        <Text style={styles.title}>{i18n.t('store.CartShippingInformationScreen_Title')}</Text>
      <View style={styles.shippingTypeContainer}>
      <Text style={styles.text}>{shippingInternational}</Text>
      <Text style={styles.text}>{shippingAmount} ₪</Text>
      </View>
      </View>
        </View>

        <View style={styles.orderSummeryContainer}>
          <>
          <OrderSummeryComponent
  userSettingsState={userSettingsState}
  navigation={navigation}
  displayAsFinalProduct={true}
  showShippingFee={true}
      />
          <View style={styles.buttonContainer}>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.button}
          // styleButton={{ backgroundColor: Gold }}
          onPress={saveInfoHandler}
          >
            {i18n.t('store.CartInformationScreen_SubmitButton')}
          </ButtonTypeButton>
        </View>
          </>
      </View>

      </View>





      </View>

      </View>
      </>
      </TouchableWithoutFeedback>
    </ScrollView>
    </KeyboardAvoidingView>
    </Screen>

}

CartInformationScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  subContainer: {
    flex: 1,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.8 : null,
    alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
  },
  title: {
    fontSize: responsiveStyles.fontSize_H3,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: '700',
    textAlign: 'center',
    paddingBottom: 20,
  },
  textInputWithErrorComponentstyleContainer: {
    // marginBottom: 50,
  },
  buttonContainer: {
    alignItems:'center',
    // flex:1
  },
  button: {
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.85, // Use wp function here
  },
  mainContainer: {
    flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
  },
  orderSummeryContainer:
  {
    flex:1, 
    marginBottom: 20, 
    paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : null,
   },
  textInputContainer: {
    // flex:1,
  },
  textInputRowContainer: {
    flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'row' : null,
    justifyContent: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?  'space-between' : null,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.35 : responsiveStyles.screenWidth * 0.85,
    marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : null,
    alignSelf: responsiveStyles.isTablet || responsiveStyles.isMobile ? 'center' : null,
},
inputStyle: {
    // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? (responsiveStyles.screenWidth) * 0.2 : null,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? (responsiveStyles.screenWidth) * 0.15 : null,
},

shippingTypeContainer: {
  flexDirection: 'row',
  padding: 20,
  width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.35 : responsiveStyles.screenWidth * 0.85,
  borderRadius: 15,
  borderWidth: 1,
  borderColor: GlobalStyles.colors.Lavender,
  justifyContent: 'space-between',
  marginBottom: 20,
  alignSelf:'center'
},
text: {
  color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  fontSize: responsiveStyles.fontSize_H5,
  fontFamily: GlobalStyles.fonts.application_font,
  textAlign: 'center',
},
});

export default CartInformationScreen;
