import React from "react";
import { StyleSheet, View, Text, TouchableOpacity, Platform, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import ImageProfile from "../images/ImageProfile";
import useResponsiveStyles from "../../functions/ResponsiveStyles";

const UserImageComponent = ({ userSettingsState, user }) => {

  const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);
  return (
    <TouchableOpacity style={styles.userPhotoContainer}>
    <ImageProfile
    userSettingsState={userSettingsState}
   uri={user.UserImage}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
  // userName={user?.UserName}
    />
  </TouchableOpacity>
  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  userPhotoContainer: {
    justifyContent:'center',
    alignItems:'center',
    // marginTop: 85,
    marginTop: responsiveStyles.screenWidth > 1200 ? 400 - (70 + 30) : !responsiveStyles.isWeb ?  150 - (70 + 30) : (responsiveStyles.screenWidth / 3) - 70,
    paddingBottom: 10,
    marginHorizontal: 
    (responsiveStyles.screenWidth / 2) - ((responsiveStyles.screenWidth > 1200 ? 200 : 150) / 2),
  },
  userPhoto: {
    // width: 125,
    // height: 125,
    width: responsiveStyles.screenWidth > 1200 ? 200 : 150,
    height: responsiveStyles.screenWidth > 1200 ? 200 : 150,
    borderRadius: 100,
    borderWidth: 4,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    // marginBottom: 10,
  },
});

export default UserImageComponent;