import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, useWindowDimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { FlatList } from "react-native-gesture-handler";
import ImageProfile from "../images/ImageProfile";
import JewleryDisplayTypeComponent from "../myAccountScreen/JewleryDisplayTypeComponent";
import JewleryListComponent from "../myAccountScreen/JewleryListComponent";
import ProfileListComponent from "../myAccountScreen/ProfileListComponent";
import OrderListComponent from "../myAccountScreen/OrderListComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
  const JewleryComponent = ({ 
    userSettingsState, 
    navigation, 
    cleanup,
    profiles = [], 
    jewlery = [], 
    orders = [],
    setDisplayType,
    displayType = 'Jewlery',
  }) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

  return (
<View style={styles.container}>

<JewleryDisplayTypeComponent
userSettingsState={userSettingsState}
 displayType={displayType} 
 setDisplayType={setDisplayType}
  />
{displayType === 'Orders' ? 
 <OrderListComponent 
 data={orders} 
 userSettingsState={userSettingsState} 
 navigation={navigation}
 cleanup={cleanup}
 />
: <JewleryListComponent 
data={jewlery} 
userSettingsState={userSettingsState} 
navigation={navigation}
cleanup={cleanup}
 />
  }
</View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex:1,
    marginHorizontal: 
    responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.18
    : !responsiveStyles.isMobile ? responsiveStyles.screenWidth * 0.2 
    : responsiveStyles.screenWidth * 0.1,
    marginTop: 10,

    },
});

export default JewleryComponent;