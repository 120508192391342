export default {
    Email: "support@scangems.com",
    Phone: "0587257993",
    AboutUs: "אודותינו",
    AboutUs_Description: "סרוק תליון TMJ כדי ללמוד וליצור אינטראקציה עם אהוביך. תוכל גם להתחיל לתעד",
    AboutUs_Address: "כתובת 70, עיר, מדינה, מיקוד",
    FooterLinks_Title: "קישורים מהירים",
    FooterLinks_Home: "דף הבית",
    FooterLinks_AboutUs: "אודותינו",
    FooterLinks_AMBASSADOR: "הפוך לשגריר",
    FooterLinks_Help: "מרכז עזרה",
    FooterLinks_Terms: "תנאים והתניות",
    FooterLinks_Privacy: "פרטיות",
    FooterLinks_ContactUs: "צור קשר",
    FooterLinks_Tutorial: "הדרכה",
};
