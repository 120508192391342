import AsyncStorage from "@react-native-async-storage/async-storage";
import { jewlery, orders, profiles } from "../../dummyData/AccountData";
import { media } from "../../dummyData/ProfileData";
import { tributes } from "../../dummyData/Profile_TributesData";
import { images, UserProfile_user as user_MediaPost, comments } from "../../dummyData/UserPofile_ImageMedia";
import { admins, user } from "../../dummyData/UserProfile_FullInfoData";
import { getResponseByErr, getResponseSucceed } from "../../functions/getResponseByErr";
import { getAsync, post, postAsync } from "../categoryApiCalls";
import { token_Storage } from "../../modules/StorageObjects";

import { Mother_PlainUser, Mother_User } from "../../constants/Profiles/Mother/User";
import { Mother_Media } from "../../constants/Profiles/Mother/MediaData";
import { Mother_Tributes } from "../../constants/Profiles/Mother/TributesData";
import { Partner_Media } from "../../constants/Profiles/Wife/MediaData";
import { Partner_Tributes } from "../../constants/Profiles/Wife/TributesData";
import { Partner_PlainUser, Partner_User } from "../../constants/Profiles/Wife/User";
import { Pet_Media } from "../../constants/Profiles/Pet/MediaData";
import { Pet_Tributes } from "../../constants/Profiles/Pet/TributesData";
import { Pet_User } from "../../constants/Profiles/Pet/User";


const GetUserProfileByIdByJewelryId = async (Id) => {
    try {
        const req = await getAsync(
          "/UserProfile/GetUserProfileByIdByJewelryId/" + Id
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from GetUserProfileByIdByJewelryId ${err}`);
      return getResponseByErr(err);
    }
  };

const GetUserProfileById = async (Id) => {
  console.log(`GetUserProfileById Id is ${Id}`);
  if(Id === "Mother"){
    let vm = {
      User: Mother_User,
      Tributes: Mother_Tributes,
      Media: Mother_Media,
    };
    return getResponseSucceed(vm);
  }
  if(Id === "Partner"){
    let vm = {
      User: Partner_User,
      Tributes: Partner_Tributes,
      Media: Partner_Media,
    };
    return getResponseSucceed(vm);
  }
  if(Id === "Pet"){
    let vm = {
      User: Pet_User,
      Tributes: Pet_Tributes,
      Media: Pet_Media,
    };
    return getResponseSucceed(vm);
  }
  // const token = await AsyncStorage.getItem(token_Storage);
  // if(!token){
  //   let vm = {
  //     User: user,
  //     Tributes: tributes,
  //     Media: media,
  //   };
  //   return getResponseSucceed(vm);
  // }
  // if(!Id){
  //   let vm = {
  //     User: user,
  //     Tributes: tributes,
  //     Media: media,
  //   };
  //   return getResponseSucceed(vm);

  // }
    try {
        const req = await getAsync(
          "/UserProfile/GetUserProfileById/" + Id
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from GetUserProfileById ${err}`);
      return getResponseByErr(err);
    }
  };

const GetUserProfileFullInfoById = async (Id) => {
  // const token = await AsyncStorage.getItem(token_Storage);
  // if(!token){
  //   let vm = {
  //     User: user,
  //     Tributes: tributes,
  //     Media: media,
  //     Admins: admins,
  //   };
  //   return getResponseSucceed(vm);
  // }
      try {
          const req = await getAsync(
            "/UserProfile/GetUserProfileFullInfoById/" + Id
          );
          const response = req.data;
          if (response.isCompleted) {
            const vm = JSON.parse(response.answer);
          }
          else {
              throw new Error('BadRequest');
            }
            return response;
      } catch (err) {console.log(`err from GetUserProfileFullInfoById ${err}`);
        return getResponseByErr(err);
      }
  };
  const GetUserProfilePostMediaByJewelry = async (JewelryId, PostId) => {  
    try {
      const vm = {
        JewelryId,
        PostId
      }
        const req = await postAsync(
            "/UserProfile/GetUserProfilePostMediaByJewelry", vm
          );
          const response = req.data;
          if (response.isCompleted) {
            // const vm = JSON.parse(response.answer);
          }
          else {
              throw new Error('BadRequest');
            }
            return response;
      } catch (err) {console.log(`err from GetUserProfilePostMediaByJewelry ${err}`);
        return getResponseByErr(err);
      }
  };
  const GetUserProfilePostMedia = async (PostId) => {

    if(PostId < 0){
      // check by PostId which one i should take
      if(PostId < -10000 && PostId > -19999){
      let media = Mother_Media.find(s => s.Id === PostId);
      let album = [];
      let plainUser = null;
      if(media){
        album = Mother_Media.find(s => s.Id === PostId)?.album;
        plainUser = Mother_PlainUser;
        plainUser.Title = media.Title;
        plainUser.Description = media.Description;
        if(album){
          album.forEach(item => {
            item.id = item.Id;
            item.type = item.MediaType;
            item.uri = item.URL;
            item.duration = item.Duration;
          });
        }
      }
      const ress = {
        User: plainUser,
        Images: album,
        Comments: [],
      }
      return getResponseSucceed(ress);
    }
    if(PostId < -20000 && PostId > -29999){
      let media = Partner_Media.find(s => s.Id === PostId);
      let album = [];
      let plainUser = null;
      if(media){
        album = Partner_Media.find(s => s.Id === PostId)?.album;
        plainUser = Partner_PlainUser;
        plainUser.Title = media.Title;
        plainUser.Description = media.Description;
        if(album){
          album.forEach(item => {
            item.id = item.Id;
            item.type = item.MediaType;
            item.uri = item.URL;
            item.duration = item.Duration;
          });
        }
      }
      const ress = {
        User: plainUser,
        Images: album,
        Comments: [],
      }
      return getResponseSucceed(ress);
    }
    }
    // const token = await AsyncStorage.getItem(token_Storage);
    // if(!token){
    //   const ress = {
    //     Images: images,
    //     User: user_MediaPost,
    //     Comments: comments,
    //   };
    //   return getResponseSucceed(ress);
    // }
  
    try {
        const req = await getAsync(
            "/UserProfile/GetUserProfilePostMedia/" + PostId
          );
          const response = req.data;
          if (response.isCompleted) {
            const vm = JSON.parse(response.answer);
          }
          else {
              throw new Error('BadRequest');
            }
            return response;
      } catch (err) {console.log(`err from GetUserProfilePostMedia ${err}`);
        return getResponseByErr(err);
      }
  };
  
  const GetUserProfilePostTribute = async (TributeId) => {
    // const token = await AsyncStorage.getItem(token_Storage);
    // if(!token){
    //   const ress = {
    //     Images: images,
    //     User: user_MediaPost,
    //     Comments: comments,
    //   };
    //   return getResponseSucceed(ress);
    // }
  
    try {
        let vm = {
          TributeId: TributeId,
        };
          const req = await getAsync(
            "/UserProfile/GetUserProfilePostTribute/" + TributeId
          );
          const response = req.data;
          if (response.isCompleted) {
            const vm = JSON.parse(response.answer);
          }
          else {
              throw new Error('BadRequest');
            }
            return response;
      } catch (err) {console.log(`err from GetUserProfilePostTribute ${err}`);
        return getResponseByErr(err);
      }
  };
  const DeleteUserProfile = async (Id) => {
        try {
            const req = await getAsync(
              "/UserProfile/DeleteUserProfile/" + Id
            );
            const response = req.data;
            if (response.isCompleted) {
              const vm = JSON.parse(response.answer);
            }
            else {
                throw new Error('BadRequest');
              }
              return response;
        } catch (err) {console.log(`err from DeleteUserProfile ${err}`);
          return getResponseByErr(err);
        }
    };
 
const CreateUserProfileInfo = async (data) => {
  try {
      const req = await postAsync(
        "/UserProfile/CreateUserProfileInfo/", data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from CreateUserProfileInfo ${err}`);
    return getResponseByErr(err);
  }
};
const UpdateUserProfileInfo = async (data) => {
        try {
            const req = await postAsync(
              "/UserProfile/UpdateUserProfileInfo/", data,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                }
              }
            );
            const response = req.data;
            if (response.isCompleted) {
              const vm = JSON.parse(response.answer);
            }
            else {
                throw new Error('BadRequest');
              }
              return response;
        } catch (err) {console.log(`err from UpdateUserProfileInfo ${err}`);
          return getResponseByErr(err);
        }
  };
const AddMediaPost = async (data) => {
  try {
      const req = await postAsync(
        "/MediaPost/AddMediaPost", data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }, false
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from AddMediaPost ${err}`);
    return getResponseByErr(err);
  }
};
const UpdateMediaPost = async (data) => {
  try {
      const req = await postAsync(
        "/MediaPost/UpdateMediaPost", data
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from UpdateNewMediaPost ${err}`);
    return getResponseByErr(err);
  }
};


const DeleteMediaPost = async (Id) => {
  try {
      const req = await getAsync(
        "/MediaPost/Delete/" + Id
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from DeleteMediaPost ${err}`);
    return getResponseByErr(err);
  }
};
const RestoreMediaPost = async (Id) => {
  try {
      const req = await getAsync(
        "/MediaPost/Activate/" + Id
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from RestoreMediaPost ${err}`);
    return getResponseByErr(err);
  }
};


const AddTributePostInvitation = async (data) => {
  try {
      const req = await postAsync(
        "/TributePost/AddTributePostInvitation/", data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }, false
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from AddTributePostInvitation ${err}`);
    return getResponseByErr(err);
  }
};
const AddTributePost = async (data) => {
  try {
      const req = await postAsync(
        "/TributePost/AddTributePost/", data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        }, false
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from AddTributePost ${err}`);
    return getResponseByErr(err);
  }
};
const AcceptTributePostInvitation = async (Id) => {
  try {
      const req = await getAsync(
        "/TributePost/AcceptTributePostInvitation/" + Id
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from AcceptTributePostInvitation ${err}`);
    return getResponseByErr(err);
  }
};
const DeclineTributePostInvitation = async (Id) => {
  try {
      const req = await getAsync(
        "/TributePost/DeclineTributePostInvitation/" + Id
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from DeclineTributePostInvitation ${err}`);
    return getResponseByErr(err);
  }
};
const DeleteTributePost = async (Id) => {
  try {
      const req = await getAsync(
        "/TributePost/Delete/" + Id
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from DeleteTributePost ${err}`);
    return getResponseByErr(err);
  }
};
const RestoreTributePost = async (Id) => {
  try {
      const req = await getAsync(
        "/TributePost/Activate/" + Id
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from RestoreTributePost ${err}`);
    return getResponseByErr(err);
  }
};
const UpdateTributePost = async (data) => {
  try {
      const req = await postAsync(
        "/TributePost/UpdateTributePost/", data
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from UpdateTributePost ${err}`);
    return getResponseByErr(err);
  }
};


const GetOrderById = async (OrderId) => {
  // const token = await AsyncStorage.getItem(token_Storage);
  // if(!token){
  // const order = orders[0];
  // return getResponseSucceed(order);
  // }
  try {

        const req = await getAsync(
          "/Account/GetOrderById/" + OrderId
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from GetOrderById ${err}`);
      return getResponseByErr(err);
    }
};

const GetOrderPaymentSuccessfulById = async (OrderId) => {
  // const token = await AsyncStorage.getItem(token_Storage);
  // if(!token){
  // const order = orders[0];
  // return getResponseSucceed(order);
  // }
  try {

        const req = await getAsync(
          "/Order/GetOrderPaymentSuccessfulById/" + OrderId
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from GetOrderPaymentSuccessfulById ${err}`);
      return getResponseByErr(err);
    }
};

const GetJewleryById = async (JewleryId) => {
//   const token = await AsyncStorage.getItem(token_Storage);
//   if(!token){
//   const vm = {
//     Jewlery: jewlery[0],
//     Profile: profiles[0],
//   }
//   return getResponseSucceed(vm);
// }
  try {

        const req = await getAsync(
          "/Account/GetJewleryById/" + JewleryId
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from GetJewleryById ${err}`);
      return getResponseByErr(err);
    }
};

const GetJewleryByIdAndProfiles = async (JewleryId) => {
//   const token = await AsyncStorage.getItem(token_Storage);
//   if(!token){
//   const vm = {
//     Jewlery: jewlery[0],
//     Profiles: profiles,
//   }
//   return getResponseSucceed(vm);
// }
  try {

        const req = await getAsync(
          "/Account/GetJewleryByIdAndProfiles/" + JewleryId
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from GetJewleryByIdAndProfiles ${err}`);
      return getResponseByErr(err);
    }
};

const AssignProfileToJewlery = async (data) => {
  try {
      const req = await postAsync(
        "/Account/AssignProfileToJewlery/", data
      );
      const response = req.data;
      if (response.isCompleted) {
        const vm = JSON.parse(response.answer);
      }
      else {
          throw new Error('BadRequest');
        }
        return response;
  } catch (err) {console.log(`err from AssignProfileToJewlery ${err}`);
    return getResponseByErr(err);
  }
};


const AddRequestUserAdmin = async (Id) => {
    try {
        const req = await getAsync(
          "/UserProfileAdminRequest/AddRequestUserAdmin/" + Id
        );
        const response = req.data;
          return response;
    } catch (err) {
      console.log(`err from AddRequestUserAdmin ${err}`);
      return getResponseByErr(err);
    }
  };
  const CancelRequestUserAdmin = async (Id) => {
    try {
        const req = await getAsync(
          "/UserProfileAdminRequest/CancelRequestUserAdmin/" + Id
        );
        const response = req.data;
          return response;
    } catch (err) {
      console.log(`err from CancelRequestUserAdmin ${err}`);
      return getResponseByErr(err);
    }
  };


    export { 
      GetUserProfileById, 
      GetUserProfileByIdByJewelryId,
      GetUserProfileFullInfoById, 
      CreateUserProfileInfo, 
      UpdateUserProfileInfo, 

      AddMediaPost, 
      UpdateMediaPost, 
      DeleteMediaPost,
      RestoreMediaPost,


      AddTributePost, 
      AddTributePostInvitation,
      AcceptTributePostInvitation,
      DeclineTributePostInvitation,
      UpdateTributePost, 
      DeleteTributePost,
      RestoreTributePost,

      GetUserProfilePostMedia,
      GetUserProfilePostMediaByJewelry,
      GetUserProfilePostTribute,
      GetOrderById,
      GetOrderPaymentSuccessfulById,
      GetJewleryById,
      GetJewleryByIdAndProfiles,
      AssignProfileToJewlery,
      DeleteUserProfile,


      AddRequestUserAdmin,
      CancelRequestUserAdmin,
     };