import React, { useContext, useEffect, useRef, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, Dimensions, Platform, useWindowDimensions, TouchableWithoutFeedback, Keyboard, KeyboardAvoidingView } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { Context as ShopContext } from "../../context/ShopContext";
import { GlobalStyles, Gold } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import CartBreadcrumbs from '../../components/store/CartBreadcrumbs';
import OrderSummeryComponent from '../../components/store/OrderSummeryComponent';
import OrderShippingContainer from '../../components/store/OrderShippingContainer';
import TextInputWithErrorComponent from '../../components/forms/TextInputWithErrorComponent';
import { getDiscountPrice, getTotalPrice, getTotalQuantity } from '../../functions/getShopInfo';
import { OrderCheckout } from '../../api/apiCalls/OrderCalls';
import { navigateToScreen } from '../../navigationResponsive';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import { isNumeric } from '../../functions/utils';
import ErrorComponentMessageError from '../../components/error/ErrorComponentMessageError';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
const CartPaymentScreen = ({  }) => {
  const {state: userSettingsState } = useContext(UserSettingsContext);
  const {state: shopState, updatePaymentDetails, finishedOrder } = useContext(ShopContext);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const validateExpirationDate = (expirationDate, checkMonth) => {
      if (!expirationDate || expirationDate.length < 4) {
        return false;
      }
    
      if (checkMonth) {
        const monthPart = expirationDate.substring(0, 2);
        const month = parseInt(monthPart, 10);
    
        if (isNaN(month) || month < 1 || month > 12) {
          return false;
        }
      } else {
        const currentYear = new Date().getFullYear() % 100; // Last two digits of the current year
        const yearPart = expirationDate.substring(2, 4);
        const year = parseInt(yearPart, 10);
    
        if (isNaN(year) || year <= (currentYear - 1) || year > 99) {
          return false;
        }
      }
    
      return true;
    };
    const scrollViewRef = useRef(null);
    const [inputs, setInputs] = useState({
      CardNumber: {
        value: '',
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      NameOnCard: {
        value: '',
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      ExpirationDate: {
        value: '',
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
      SecurityCode: {
        value: '',
        isValid: true,
        isChanged: false,
        layoutY: 0,
      },
    });
    const onLayoutChange = (inputName, y) => {

      setInputs(curInputs => ({
        ...curInputs,
        [inputName]: { ...curInputs[inputName], layoutY: y },
      }));
    };

    function inputChangedHandler(inputIdentifier, enteredValue,isChanged) {
      setInputs((curInputs) => {
        const newInputs = {
          ...curInputs,
          [inputIdentifier]: { value: enteredValue, isValid: true, isChanged: isChanged },
        };
        return newInputs;
      });
      return;
    }
    const getFileName = (url) => {
      try{
        if(responsiveStyles.isWeb){
          // Remove the leading part of the URL
          const cleanedUrl = url.replace("/static/media/", "");
          
          // Split the string at the dot and return the first part
          const fileNameWithExtension = cleanedUrl.split('.')[0];
          
          // Add the extension back
          const fileExtension = cleanedUrl.split('.').pop();
          
          return `${fileNameWithExtension}.${fileExtension}`;
        }
        else{
          let decodedUrl = decodeURIComponent(url);
          let startIndex = decodedUrl.indexOf('images/') + 'images/'.length;
          let endIndex = decodedUrl.indexOf('?platform');

          // Extract the substring
          let imagePath = decodedUrl.substring(startIndex, endIndex);

          console.log(`decodedUrl, imagePath is ${imagePath}`);
          return imagePath;
        }
      }
      catch(err){
        console.log(`func getFileName failed, err: ${err}`);
      }
    };
    const saveInfoHandler = async () => {
      try{


      const CardNumberIsValidLimit = isNumeric(inputs.CardNumber.value) && 
      (inputs.CardNumber.value.length >= 15 &&
        inputs.CardNumber.value.length <= 16);
      const CardNumberIsValid = inputs.CardNumber.value.toString().trim().length > 0 && CardNumberIsValidLimit;

      const NameOnCardIsValid = inputs.NameOnCard.value.toString().trim().length > 0;


      const ExpirationDateIsValidLimit = isNumeric(inputs.ExpirationDate.value) &&
      inputs.ExpirationDate.value.length === 4
      && validateExpirationDate(inputs.ExpirationDate.value, true)
      && validateExpirationDate(inputs.ExpirationDate.value, false)
      ;
      const ExpirationDateIsValid = inputs.ExpirationDate.value.toString().trim().length > 0 && ExpirationDateIsValidLimit;

      const SecurityCodeIsValidLimit = isNumeric(inputs.SecurityCode.value) &&
      inputs.SecurityCode.value.length === 3;
      const SecurityCodeIsValid = inputs.SecurityCode.value.toString().trim().length > 0 && SecurityCodeIsValidLimit;

      if (!CardNumberIsValid
         || !NameOnCardIsValid 
         || !ExpirationDateIsValid
         || !SecurityCodeIsValid
        ) {
        setInputs((curInputs) => {
          const updatedInputs = { ...curInputs };
          
          
          updatedInputs.CardNumber.isValid = CardNumberIsValid;
          updatedInputs.CardNumber.isChanged = updatedInputs.CardNumber.isChanged === true ? true :CardNumberIsValidLimit;
          
          updatedInputs.NameOnCard.isValid = NameOnCardIsValid;
          
          updatedInputs.ExpirationDate.isValid = ExpirationDateIsValid;
          updatedInputs.ExpirationDate.isChanged = updatedInputs.ExpirationDate.isChanged === true ? true : ExpirationDateIsValidLimit;

          
          updatedInputs.SecurityCode.isValid = SecurityCodeIsValid;
          updatedInputs.SecurityCode.isChanged = updatedInputs.SecurityCode.isChanged === true ? true : SecurityCodeIsValidLimit;
          
          console.log(`updatedInputs: ${JSON.stringify(updatedInputs)}`);
          return updatedInputs;
        });
        if (!CardNumberIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.CardNumber.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.CardNumber.layoutY, x: inputs.CardNumber.layoutY, animated: true });
          }
        } 

        else if (!NameOnCardIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.NameOnCard.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.NameOnCard.layoutY, x: inputs.NameOnCard.layoutY, animated: true });
          }
        } 

        else if (!ExpirationDateIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.ExpirationDate.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.ExpirationDate.layoutY, x: inputs.ExpirationDate.layoutY, animated: true });
          }
        } 

        else if (!SecurityCodeIsValid) {
          if(responsiveStyles.isWeb){
            window.scrollTo(0, inputs.SecurityCode.layoutY);
          }
          else if(scrollViewRef.current){
            scrollViewRef.current.scrollTo({ y: inputs.SecurityCode.layoutY, x: inputs.SecurityCode.layoutY, animated: true });
          }
        } 
        return;
      }

      let DataToSend = {
        CardNumber: inputs.CardNumber.value,
        NameOnCard: inputs.NameOnCard.value,
        ExpirationDate: inputs.ExpirationDate.value,
        SecurityCode: inputs.SecurityCode.value,
    }
    await updatePaymentDetails(DataToSend);

    let OrderCheckoutVM = {
      Order: {
        OrderId: 0, // server need to give OrderId
        OrderDate: new Date(), // server need to update that too
        OrderStatus: "Pending",
        PaymentStatus: "Not paid",
        UserId: userSettingsState?.userSettings?.UserId,
        ShippingAddressID: 0, // server need to assign that
        ShippingTypeID: shopState.shippingMethod.Id, // server need to assign that
        PaymentID: 0, // server need to assign that
        Subtotal: getTotalPrice(shopState, false, false),
        DiscountCode: shopState.discount?.discountCode,
        Discounts: getDiscountPrice(shopState),
        Taxes: 0, // right now i dont count taxes
        Total: getTotalPrice(shopState, true, true),
        SpecialInstructions: "",
        GiftWrap: false,
        GiftMessage: "",
        },
        ShippingType: {
          ShippingTypeId: shopState.shippingMethod.Id,
          Type: shopState.shippingMethod.type,
          Amount: shopState.shippingMethod.price,
        },
        Payment: {
          PaymentId: 0, // server need to give OrderId
          Amount: getTotalPrice(shopState, true, true),
          CardNumber: DataToSend.CardNumber,
          NameOnCard: DataToSend.NameOnCard,
          SecurityCode: DataToSend.SecurityCode,
          ExpirationDateMonth: DataToSend.ExpirationDate.slice(0, 1),
          ExpirationDateYear: DataToSend.ExpirationDate.slice(2, 3),
          PaymentMethod: "Card",
          PaymentMethodId: 0, // server need to give OrderId
          PaymentStatus: "Unpaid",
          PaymentDate: new Date(),
          PaymentTransactionId: "PaymentTransactionId", // server need to give OrderId
        },
        ShippingAddress: {
          AddressId: 0, // server need to give OrderId
          UserId: userSettingsState?.userSettings?.UserId,
          Country: shopState.shippingDetails.Country,
          City: shopState.shippingDetails.City,
          FirstName: shopState.shippingDetails.FirstName,
          LastName: shopState.shippingDetails.LastName,
          AddressLine: shopState.shippingDetails.Address,
          Apartment: shopState.shippingDetails.Apartment,
          PostalCode: shopState.shippingDetails.PostalCode,
          PhoneNumber: shopState.shippingDetails.Phone,

        },
        OrderItems: [],
    };
    console.log(`shopState.cart.forEach(item => {`);
    shopState.cart.forEach(item => {
      console.log(`item is `);
      console.log(item);
      const isWithDiscount = item.item.Discount > 0;
      const FinalPrice = isWithDiscount ? (item.item.Price * (100 - item.item.Discount) / 100).toFixed(2) : item.item.Price;
      const OrderItem = {
        OrderItemId: 0, // server need to give OrderId
        OrderId: 0, // server need to give OrderId
        ProductId: item.item.Id, // server need to give OrderId
        ProductName: item.item.Name,
        FrontSideText: item.item.frontSideText,
        BackSideText: item.item.backSideText,
        ImagePath: getFileName( item.item.Image ? item.item.Image : item.item.Images?.length > 0 ? item.item.Images[0] : ""),
        Quantity: item.quantity,
        UnitPrice: FinalPrice,
        TotalPrice: FinalPrice * item.quantity,
      };
      OrderCheckoutVM.OrderItems.push(OrderItem);
    });
    console.log(`OrderCheckoutVM is`);
    console.log(JSON.stringify(OrderCheckoutVM));
    const response = await OrderCheckout(OrderCheckoutVM);
    if(response.isCompleted === true)
      {
        // remove all parts from cart + user details
        const OrderId = JSON.parse(response.answer);
        finishedOrder();
        navigateToScreen(navigation, 'StoreFlow', `PaymentSuccessful`, { OrderId: OrderId }, OrderId)
        // navigation.navigate('StoreFlow', { screen: 'PaymentSuccessful' });
        // navigation.navigate("PaymentSuccessful");
      }
      else{
        setbodymessge(response.message);
        setGenericAlertVisible(true);
      }
    }
    catch(err){
      console.log(`func saveInfoHandler failed, err: ${err}`);
    }
    };
    
    const formatExpirationDate = (value) => {
      // Remove any non-numeric characters
      const numericValue = value.replace(/\D/g, '');
    
      // Format the value as MM/YY
      if (numericValue.length <= 2) {
        return numericValue;
      } else if (numericValue.length <= 4) {
        return `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}`;
      } else {
        return `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}`;
      }
    };
    const getFirstLetters = (str, number) => {
      if (typeof str !== 'string') {
        return str;
      }
      
      return str.substring(0, number);
    };
    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Cart Payment";
      }
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);



    
  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showBackButton={true}
  >
  <KeyboardAvoidingView
      behavior={'padding'}
      style={styles.container}
      >
  <ScrollView 
  ref={scrollViewRef}
      contentContainerStyle={styles.scrollViewContent}
      keyboardShouldPersistTaps='handled'
  >
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
    <>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
  <View style={styles.subContainer}>
      <View style={styles.header}>
      {/* <OrderSummeryComponent
  userSettingsState={userSettingsState}
  showShippingFee={true}
  navigation={navigation}
      /> */}
      <CartBreadcrumbs
      userSettingsState={userSettingsState}
      navigation={navigation}
      showInformation={true}
      showShipping={true}
      showPayment={true}
      />
      </View>
      <View style={{padding: 20}}>
        
      <View style={styles.mainContainer}>
      <View style={styles.textInputContainer}>
      <Text style={styles.title}>{i18n.t('store.CartPaymentScreen_Title')}</Text>
    <View style={styles.textInputRowContainer}
      onLayout={(event) => {
          onLayoutChange('CardNumber', event.nativeEvent.layout.y);
          onLayoutChange('NameOnCard', event.nativeEvent.layout.y);
      }}
    >
      <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    keyboardType={'numeric'}
    minLetters={15}
    maxLetters={16}
    value={getFirstLetters(inputs.CardNumber.value,16)}
    setValue={(value) => {
      if(value.length > 16){
        
      }
      else{
        inputChangedHandler("CardNumber", value, true); 
      }
    }}
    label={`${i18n.t('store.CartPaymentScreen_Inputs_CardNumber')}*`}
    errorMessage={i18n.t('store.CartPaymentScreen_Inputs_CardNumber_ErrorMessage')}
    isRequired={true}
    isValid={inputs.CardNumber.isValid}
    isChanged={inputs.CardNumber.isChanged}
    styleContainer={styles.textInputWithErrorComponentstyleContainer}
    styleTextInputContainer={styles.inputStyle}
    />
<TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    value={inputs.NameOnCard.value}
    setValue={(value) => {
        inputChangedHandler("NameOnCard", value, true);
    }}
    label={`${i18n.t('store.CartPaymentScreen_Inputs_NameOfCard')}*`}
    errorMessage={i18n.t('store.CartPaymentScreen_Inputs_NameOfCardr_ErrorMessage')}
    isRequired={true}
    isValid={inputs.NameOnCard.isValid}
    isChanged={inputs.NameOnCard.isChanged}
    styleContainer={[styles.textInputWithErrorComponentstyleContainer, responsiveStyles.isDesktop || responsiveStyles.isLaptop ? {marginLeft:  responsiveStyles.screenWidth * 0.015} : null]}
    styleTextInputContainer={styles.inputStyle}
    />


    </View>
    <View style={styles.textInputRowContainer}
      onLayout={(event) => {
        onLayoutChange('ExpirationDate', event.nativeEvent.layout.y);
        onLayoutChange('SecurityCode', event.nativeEvent.layout.y);
    }}
    >
  <View style={{flexDirection:'column'}}>
   <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    keyboardType={'numeric'}
    minLetters={5} // should be 4 but because of the format (which i added '/') its 4 + 1 = 5
    minLettersShowcase={4}
    maxLetters={5}
    maxLettersShowcase={4}
    excludeIsNumeric={"/"}
    // value={inputs.ExpirationDate.value}
    value={formatExpirationDate(inputs.ExpirationDate.value)}
    setValue={(tempValue) => {
      const value = tempValue.replace("/","");
      if(value.length > 4){
        
      }
      else{
        inputChangedHandler("ExpirationDate", value, true);
      }
    }}
    label={`${i18n.t('store.CartPaymentScreen_Inputs_ExpirationDate')}*`}
    errorMessage={i18n.t('store.CartPaymentScreen_Inputs_ExpirationDate_ErrorMessage')}
    isRequired={true}
    isValid={inputs.ExpirationDate.isValid}
    isChanged={inputs.ExpirationDate.isChanged}
    styleContainer={styles.textInputWithErrorComponentstyleContainer}
    styleTextInputContainer={styles.inputStyle}
    />
    {
      inputs.ExpirationDate.value.length === 4 ?
      Number(inputs.ExpirationDate.value) && !validateExpirationDate(inputs.ExpirationDate.value, true)
      ?
      <ErrorComponentMessageError
      userSettingsState={userSettingsState} 
      errorMessage={`${i18n.t('textInput.expirationDate_monthError')}`} 
      />
      :  Number(inputs.ExpirationDate.value) && !validateExpirationDate(inputs.ExpirationDate.value, false)
      ?
      <ErrorComponentMessageError
      userSettingsState={userSettingsState} 
      errorMessage={`${i18n.t('textInput.expirationDate_yearError')}`} 
      />
      : null
      : null
    }
    </View>
    <TextInputWithErrorComponent
    userSettingsState={userSettingsState}
    keyboardType={'numeric'}
    minLetters={3}
    maxLetters={3}
    value={getFirstLetters(inputs.SecurityCode.value,3)}
    setValue={(value) => {
      if(value.length > 3){

      }
      else{
        inputChangedHandler("SecurityCode", value, true);
      }
    }}
    label={`${i18n.t('store.CartPaymentScreen_Inputs_SecurityCode')}*`}
    errorMessage={i18n.t('store.CartPaymentScreen_Inputs_SecurityCode_ErrorMessage')}
    isRequired={true}
    isValid={inputs.SecurityCode.isValid}
    isChanged={inputs.SecurityCode.isChanged}
    styleContainer={[styles.textInputWithErrorComponentstyleContainer, responsiveStyles.isDesktop || responsiveStyles.isLaptop ? {marginLeft:  responsiveStyles.screenWidth * 0.015} : null]}
    styleTextInputContainer={styles.inputStyle}
    />

    </View>
    <OrderShippingContainer
      userSettingsState={userSettingsState}
      showShippingMeathod={true}
      onChangePress={() => {
        responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
        // responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      }}
      />
    </View>
    <View style={styles.orderSummeryContainer}>

    <>
          <OrderSummeryComponent
  userSettingsState={userSettingsState}
  navigation={navigation}
  displayAsFinalProduct={true}
  showShippingFee={true}
      />
      <View style={styles.buttonContainer}>
    <ButtonTypeButton
    userSettingsState={userSettingsState}
    style={styles.button}
    // styleButton={{ backgroundColor: Gold }}
    loading={isLoading}
    onPress={async () => {
      setIsLoading(true);
      await saveInfoHandler();
      setIsLoading(false);
  }}
    >
      {i18n.t('store.CartPaymentScreen_SubmitButton')}
</ButtonTypeButton>
</View>
  </>

</View>
</View>

</View>
</View>
</>
</TouchableWithoutFeedback>
    </ScrollView>
    </KeyboardAvoidingView>
    </Screen>

}

CartPaymentScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    },
    scrollViewContent: {
      flexGrow: 1,
    },
    subContainer: {
      flex: 1,
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.8 : null,
      alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
    },
    title: {
      fontSize: responsiveStyles.fontSize_H3,
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontFamily: GlobalStyles.fonts.application_font,
      fontWeight: '700',
      alignSelf:'center',
      paddingBottom: 20,
    },
    text: {
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
      textAlign: 'center',
    },
    mainContainer: {
      flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
    },
    orderSummeryContainer:
    {flex:1, 
      marginBottom: 20, 
      paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : null,
     },
    textInputContainer: {
  
    },
    buttonContainer: {
      alignItems:'center',
      // flex:1
    },
    button: {
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.85,
    },
    textInputRowContainer: {
      flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'row' : null,
      justifyContent: responsiveStyles.isDesktop || responsiveStyles.isLaptop ?  'space-between' : null,
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.35 : responsiveStyles.screenWidth * 0.85,
      marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 20 : null,
      alignSelf: responsiveStyles.isTablet || responsiveStyles.isMobile ? 'center' : null,
  },
  inputStyle: {
      // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? (responsiveStyles.screenWidth) * 0.2 : null,
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? (responsiveStyles.screenWidth) * 0.15 : null,
  },
});

export default CartPaymentScreen;
