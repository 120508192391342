import AsyncStorage from "@react-native-async-storage/async-storage";
import { jewlery, orders, profiles, user_PersonalDetails } from "../../dummyData/AccountData";
import { tributes } from "../../dummyData/Profile_TributesData";
import { getResponseByErr, getResponseSucceed } from "../../functions/getResponseByErr";
import { getAsync, post, postAsync } from "../categoryApiCalls";
import { token_Storage } from "../../modules/StorageObjects";

  const OrderCheckout = async (data) => {
    try {
        const req = await postAsync(
          "/Order/OrderCheckout", data, null, false
        );
        const response = req.data;
        if (response.isCompleted) {
          const vm = JSON.parse(response.answer);
        }
        else {
            throw new Error('BadRequest');
          }
          return response;
    } catch (err) {console.log(`err from SendContactUsMessage ${err}`);
      return getResponseByErr(err);
    }
  };


  export { OrderCheckout };