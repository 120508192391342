import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity, Dimensions, LogBox, Image, useWindowDimensions, Platform, ActivityIndicator, Linking } from "react-native";
import { GlobalStyles, Gold } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import { FlatList } from "react-native-gesture-handler";
import ImageProfile from "../images/ImageProfile";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import TributeEmptyListComponent from "./TributeEmptyListComponent";
import CommendComponent from "../../CommendComponent";
import CommentSectionPopup from "../CommentSectionPopup";
import LoginModalAlert from "../alert/LoginModalAlert";
import { DeleteComment, LikeTributePost, SendMediaPostMessage, SendTributePostMessage, UnlikeTributePost } from "../../api/apiCalls/MediaApiCalls";
import { GenericAlert } from "../alert/GenericAlert";
import { formatDate } from "../../functions/formatDate";
import { AcceptTributePostInvitation, DeclineTributePostInvitation, DeleteMediaPost, DeleteTributePost } from "../../api/apiCalls/ProfileCalls";
import VideoImage from "../images/VideoImage";
import { navigateToScreen } from "../../navigationResponsive";
import MediaEmptyListComponent from "./MediaEmptyListComponent";
import { Menu, MenuItem } from "react-native-material-menu";
import { Feather } from '@expo/vector-icons'; 
import TributeDisplayTypeComponent from "../myAccountScreen/TributeDisplayTypeComponent";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import ImageSlider from "../media/ImageSlider";
import { shareApp, shareTributesLink } from "../../functions/shareApp";

  const ManageTributesComponent = ({ 
    userSettingsState, 
    data : initialData  = [], 
    // data = [],
    isEditing = false,
    UserName,
    UserId: UserProfileId,
    isMediaPost = false,
    isShowUserProfileFullName = false,
    navigation,
    cleanup,
    isAdmin = false,
    IsDemo = false,
    onScrollEndParentFunction,
    isFromMyAccountScreen = false,
  }) => {

    console.log(`initialData`);
    console.log(initialData);
    
    
    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const [displayType, setDisplayType] = useState('Tributes');
    const [data, setData] = useState(initialData);

    const initialVisibleCount = 3;
    const [visibleCount, setVisibleCount] = useState(initialVisibleCount);
    const [loading, setLoading] = useState(false);

    
    const dataToDisplay = isMediaPost 
    ? data
    :  data.filter(s => s.IsActive === true 
    && (displayType === "Invitation" ? (s.IsInvitation === true && s.IsInvitationAccepted === false) : 
      (s.IsInvitation === false || (s.IsInvitation === true && s.IsInvitationAccepted === true)))
  );
    
  
  
    const loadMoreItems = 
    useCallback(
      () => {
      console.log(`loadMoreItems:
        visibleCount is ${visibleCount}
        dataToDisplay.length is ${dataToDisplay.length}
        `);
      if(visibleCount >= dataToDisplay.length){
        return;
      }
      console.log(`loadMoreItems loading: ${loading}`);
      if (loading) return;
      console.log(`loadMoreItems`);
      setLoading(true);
      setTimeout(() => {
        setVisibleCount(prevVisibleCount => prevVisibleCount + initialVisibleCount);
        setLoading(false);
      }, 200); // Simulate network delay
    }
    , [loading, visibleCount])
    ;

    onScrollEndParentFunction(loadMoreItems);

    const [isCurrentMediaPost, setIsCurrentMediaPost] = useState(isMediaPost);

    const styles = styling(responsiveStyles);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    
    useEffect(() => {
      if(isCurrentMediaPost !== isMediaPost){
        
        console.log(`useEffect in ManageTributesComponent`);
        setIsCurrentMediaPost(isMediaPost);
        setData(initialData);
        setVisibleCount(initialVisibleCount);
      }
    }, [isCurrentMediaPost !== isMediaPost]);

    const [itemSelected, setItemSelected] = useState(null);

    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [isHideMenu, setIsHideMenu] = useState(false);
    const [modalVisible, setModalVisible] = useState(null);
    const hideMenu = () => {
      if(setIsHideMenu !== null && setIsHideMenu !== undefined)
      {
        setIsHideMenu(false);
      }
      setModalVisible(null)
    };
    
    const showMenu = (Id) => {
      if(setIsHideMenu !== null && setIsHideMenu !== undefined)
      {
        setIsHideMenu(true);
      }
      setModalVisible(Id)
    };
    const openDialog = (item) => {
      console.log(`openDialog, Id is ${item?.Id}`);
      if(!userSettingsState?.userSettings?.UserId){
        setLoginModalVisible(true);
      }
      else{
        setItemSelected(item);
      }
    };
  
    const closeDialog = () => {
      setItemSelected(null);
    };

    const [bodymessge, setBodyMessage] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);

    // const sendMessageHandler = async (Id, message) => {
    //   const response = await SendTributePostMessage(Id, message);
    //   if(response.isCompleted === true)
    //   {
    //     const commentFromServer = JSON.parse(response.answer);
    //     const item = data.find(s => s.Id === Id);
    //     if (item) {
    //       item.Comments.push(commentFromServer);
    //     }
    //   }
    //   else{
    //     setBodyMessage(response.message);
    //     setGenericAlertVisible(true);
    //   }
    //   return response;
    // }
    // const DeleteCommentHandler = async (ItemId, CommentId) => {
    //   const response = await DeleteComment(CommentId, isAdmin);
    //   if(response.isCompleted === true)
    //   {
    //     const itemIndex = data.findIndex(s => s.Id === ItemId);
    //     if (itemIndex !== -1) {

    //     const updatedItem = {
    //       ...data[itemIndex],
    //       Comments: data[itemIndex].Comments.filter(comment => comment.id !== CommentId)
    //     };
    //     console.log("found item");
    //     data = [
    //       ...data.slice(0, itemIndex),
    //       updatedItem,
    //       ...data.slice(itemIndex + 1)
    //     ];
    //   }
    //   }
    //   else{
    //     setBodyMessage(response.message);
    //     setGenericAlertVisible(true);
    //   }
    //   return response;
    // }

    // const LikePostHandler = async (ItemId) => {
    //   const itemIndex = data.findIndex(s => s.Id === ItemId);
    //   let response;
    //   console.log(itemIndex);
    //   if (itemIndex !== -1) {
    //     if(data[itemIndex].IsUserLiked === true){
    //       response = await UnlikeTributePost(ItemId);
    //       const updatedItem = {
    //         ...data[itemIndex],
    //         IsUserLiked: false,
    //         Like: data[itemIndex].Like === 0 ? 0 : data[itemIndex].Like - 1,
    //       };
    //       data = [
    //         ...data.slice(0, itemIndex),
    //         updatedItem,
    //         ...data.slice(itemIndex + 1)
    //       ];
    //     }
    //     else{
    //       response = await LikeTributePost(ItemId);
    //       const updatedItem = {
    //         ...data[itemIndex],
    //         IsUserLiked: true,
    //         Like: data[itemIndex].Like === 0 ? 1 : data[itemIndex].Like + 1
    //       };
    //       data = [
    //         ...data.slice(0, itemIndex),
    //         updatedItem,
    //         ...data.slice(itemIndex + 1)
    //       ];
    //     }
    //   }
    //   else{
    //     console.log(`if (itemIndex !== -1) {`);
    //   }
    //   console.log(`data is`);
    //   console.log(data);
    //   return response;
    // }
    const sendMessageHandler = useCallback(async (Id, message) => {
      const response = isMediaPost ?
       await SendMediaPostMessage(Id, message) 
      : await SendTributePostMessage(Id, message);
      if (response.isCompleted === true) {
        const commentFromServer = JSON.parse(response.answer);
        const updatedData = data.map(item => {
          if (item.Id === Id) {
            return { ...item, Comments: [...item.Comments, commentFromServer] };
          }
          return item;
        });
        setItemSelected(prevItem => ({
          ...prevItem,
          Comments: [...prevItem.Comments, commentFromServer],
        }));
        setData(updatedData);
      } else {
        setBodyMessage(response.message);
        setGenericAlertVisible(true);
      }
      return response;
    }, [data, isMediaPost]);
    const [isAcceptTributeLoading, setIsAcceptTributeLoading] = useState(null);
    const [isDeclineTributeLoading, setIsDeclineTributeLoading] = useState(null);

    const AcceptTributePostInvitationHandler = useCallback(async (Id) => {
      setIsAcceptTributeLoading(Id);
      const response = await AcceptTributePostInvitation(Id);
      if (response.isCompleted === true) {
        const updatedData = data.map(item => {
          if (item.Id === Id) {
            return { ...item, IsActive: true, IsInvitationAccepted: true, CreatedDate: new Date() };
          }
          return item;
        });
        setData(updatedData);
      } else {
        setBodyMessage(response.message);
        setGenericAlertVisible(true);
      }
      setIsAcceptTributeLoading(null);
      return response;
    }, [data]);
    const DeclineTributePostInvitationHandler = useCallback(async (Id) => {
      setIsDeclineTributeLoading(Id);
      const response = await DeclineTributePostInvitation(Id);
      if (response.isCompleted === true) {
        const updatedData = data.map(item => {
          if (item.Id === Id) {
            return { ...item, IsActive: false, IsInvitationAccepted: false };
          }
          return item;
        });
        setData(updatedData);
      } else {
        setBodyMessage(response.message);
        setGenericAlertVisible(true);
      }
      setIsDeclineTributeLoading(null);
      return response;
    }, [data]);
    const DeleteCommentHandler = useCallback(async (ItemId, CommentId) => {
      const response = await DeleteComment(CommentId, isAdmin);
      if (response.isCompleted === true) {
        const updatedData = data.map(item => {
          if (item.Id === ItemId) {
            return { ...item, Comments: item.Comments.filter(comment => comment.Id !== CommentId) };
          }
          return item;
        });
        setItemSelected(prevItem => ({
          ...prevItem,
          Comments: prevItem.Comments.filter(comment => comment.Id !== CommentId),
        }));
        setData(updatedData);
      } else {
        // setBodyMessage(response.message);
        // setGenericAlertVisible(true);
      }
      return response;
    }, [data]);
  
    const LikePostHandler = useCallback(async (ItemId) => {
      if(IsDemo){
        return;
      }
      const updatedData = data.map(item => {
        if (item.Id === ItemId) {
          let response;
          if (item.IsUserLiked === true) {
            response = UnlikeTributePost(ItemId);
            return { ...item, IsUserLiked: false, Like: item.Like === 0 ? 0 : item.Like - 1 };
          } else {
            response = LikeTributePost(ItemId);
            return { ...item, IsUserLiked: true, Like: item.Like === 0 ? 1 : item.Like + 1 };
          }
        }
        return item;
      });
      setData(updatedData);
    }, [data, IsDemo]);


  
  return (
    <View style={{flex:1}}>
     <GenericAlert
     userSettingsState={userSettingsState}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
       <LoginModalAlert
      visible={loginModalVisible}
      setVisible={setLoginModalVisible}
      userSettingsState={userSettingsState}
      navigation={navigation}
      />
      <CommentSectionPopup
      userSettingsState={userSettingsState}
      isVisible={itemSelected !== null}
      onClose={closeDialog}
      data={itemSelected?.Comments}
      UserName={itemSelected?.UserName}
      IsDemo={IsDemo}
      sendComment={(message) => sendMessageHandler(itemSelected.Id, message)}
      DeleteComment={(Id) => DeleteCommentHandler(itemSelected.Id, Id)}
      isAdmin={isAdmin}
      />
       { isEditing && data.length > 0 ? 
      <View style={styles.buttonContainer}>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.button}
          styleButton={styles.inviteStyleButton}
           onPress={() => {
            navigateToScreen(navigation, 'AccountFlow', `ManageTributePost`, { UserId: UserProfileId }, 
              `${UserProfileId}/Create`
            );
            if(cleanup){
              cleanup();
            setData([]);
            }
          }}
          >
            {i18n.t('media.ManageTributesComponent_Create')}
      </ButtonTypeButton>
      </View>
      : null }
      {isEditing && isAdmin ? 
      <>
      <TributeDisplayTypeComponent
      userSettingsState={userSettingsState}
      displayType={displayType} 
      setDisplayType={setDisplayType} 
      PendingInvitationsCount={data.filter(s => s.IsActive === true && (s.IsInvitation === true && s.IsInvitationAccepted === false)).length}
      />
      <View style={styles.buttonContainer}>
      <ButtonTypeButton
  userSettingsState={userSettingsState}
      style={styles.button}
      onPress={() => {
        shareTributesLink(UserName,UserProfileId);
      }}
      >
        {i18n.t(`media.TributesLink`)}
  </ButtonTypeButton>
  </View>
      </>
    : null}
      <FlatList
      initialNumToRender={3} // Reduce initial render amount
      maxToRenderPerBatch={3} // Reduce number in each render batch
      updateCellsBatchingPeriod={100} // Increase time between renders
      windowSize={3} // Reduce the window size
      removeClippedSubviews={true} // Unmount components when outside of window 
      showsVerticalScrollIndicator={false}
      scrollEnabled={false}
      // nestedScrollEnabled={true}
      // style={{backgroundColor:'blue'}}
      ListFooterComponent={loading ? <ActivityIndicator /> : null}
      data={
        responsiveStyles.isWeb
        ? dataToDisplay
        : dataToDisplay.slice(0, visibleCount)
      }
      key={(item) => item.Id.toString()} 
      renderItem={
        useCallback(
        ({ item, index }) =>
      {
        const showDeletePostButton = isAdmin || item.UserId === userSettingsState?.userSettings?.UserId;
        const showEditButton = item.UserId === userSettingsState?.userSettings?.UserId;
        const IsInvitation = item.IsInvitation === true && item.IsInvitationAccepted === false;
        const onEditPress = () => {
          let ItemUserProfileId = isFromMyAccountScreen ? item.UserProfileId : UserProfileId;
          if(isMediaPost){
            navigateToScreen(navigation, 'AccountFlow', `ManageMediaPost`, { PostId: item.Id, UserId: ItemUserProfileId }, 
              `${ItemUserProfileId}/${item.Id}`
            );
          }
          else{
            navigateToScreen(navigation, 'AccountFlow', `ManageTributePost`, { PostId: item.Id, UserId: ItemUserProfileId },
              `${ItemUserProfileId}/${item.Id}`
              );
          }
          if(cleanup){
            cleanup();
            setData([]);
          }
        }
      

        
      const formattedDate = formatDate(item.CreatedDate);
      if(item.UserName === "itay4"){
        // alert(`item.UserImage: ${item.UserImage}`);
      }
  const limitedUserProfileFullName = (item.UserProfileFullName)?.length > 12 ? (item.UserProfileFullName).slice(0, 12) + '...' : item.UserProfileFullName;
     return <View style={styles.TributeContainer} key={item.Id}>
      <View style={styles.userRowContainer}>
      <ImageProfile 
      userSettingsState={userSettingsState}
   uri={item.UserImage}
  imageStyle={styles.userPhoto}
  editable={false}
  isGroup={false}
  userName={item.UserName}
    />
    <View style={styles.userNameContainer}>
      <View style={{flex:1, alignItems: !responsiveStyles.isRTL ? 'flex-start' : 'flex-end',}}>
        <Text style={styles.userName}>{item.UserName}</Text>
        </View>
        <View style={[{ 
          flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row' }, 
          responsiveStyles.isDesktop || responsiveStyles.isLaptop ? {
             paddingRight: !responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.295 : null,
             paddingLeft: responsiveStyles.isRTL ? responsiveStyles.screenWidth * 0.295 : null,
            
            } : null,
          ]}>
          <View style={{ paddingHorizontal: 10,  }}>
          { isShowUserProfileFullName ?  <Text style={styles.createdDate}>•    {limitedUserProfileFullName}</Text> : null}
          </View>
          <Text style={[styles.createdDate, { paddingHorizontal: 5 }]}>•    {formattedDate == null || formattedDate === "NaN/NaN/NaN" ? item.CreatedDate : formattedDate}</Text>
        { 
        displayType !== "Invitation" && (!IsInvitation && showDeletePostButton || showEditButton) ?
           <Menu
          visible={modalVisible === item.Id}
          anchor={
        <TouchableOpacity 
         onPress={() => showMenu(item.Id)}
         style={{marginTop: -3}}
        >
        <Feather name="more-vertical"  size={24} color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} />
        </TouchableOpacity>
          
        }
        style={{borderRadius:10}}
          onRequestClose={hideMenu}
        >
              <View style={styles.menuContainer}>

      { showEditButton ? <MenuItem textStyle={styles.menuItemText} 
      onPress={() => {
        setIsHideMenu(false);
        onEditPress();
        hideMenu();
      }}>
        <Text style={styles.menuItem}>{i18n.t(`media.CommendComponent_Edit`)}</Text>
        
      </MenuItem>
      : null}
      { showDeletePostButton ? <MenuItem textStyle={styles.menuItemText} onPress={async () => {
        setIsHideMenu(false);
        setIsDeleteLoading(true);
        let response;
        if(isMediaPost)
        {
          response = await DeleteMediaPost(item.Id);
        }
        else{
          response = await DeleteTributePost(item.Id);
        }
        if(response.isCompleted === true)
          {
            setData((prevTributes) =>
              prevTributes.filter((tribute) => tribute.Id !== item.Id)
            );
            
          }
          else{
            setBodyMessage(response.message);
            setGenericAlertVisible(true);
          }
        setIsDeleteLoading(false);
        hideMenu();
      }}>
        {isDeleteLoading ? <View style={{ display:'flex'}}>
          
          <ActivityIndicator
          size={35}
          color={GlobalStyles[responsiveStyles.ThemeValue]?.ActivityIndicatorColor}
          />
          </View>
        : <Text style={styles.menuItem}>{i18n.t(`media.ManageTributesComponent_DeleteButton`)}</Text>
        }
      </MenuItem>
      : null}
      
      </View>
      </Menu> : null}
        </View>
        </View>
        
        </View>

        <View>
          <View style={{paddingHorizontal: 70, paddingBottom: 10}}>
          <Text style={styles.title}>{item.Title}</Text>
          <Text style={styles.description}>{item.Description}</Text>
          </View>
          { item.album?.length > 0 ?
           <ImageSlider
          userSettingsState={userSettingsState} 
          images={
            item.album.map(albumItem => ({
            type: 
            (albumItem.MediaType === "audio" || albumItem.MediaType === 3) ? 3
            :(albumItem.MediaType === "video" || albumItem.MediaType === 2) ? 2
            : 1, // image
            uri: albumItem.URL,
            duration: albumItem.Duration,
            width: albumItem.Width,
            height: albumItem.Height,
          }))
        }
          isFromTributes={true}
          isThumbnail={true}
           />
          : null }

          {/* <FlatList
          initialNumToRender={2} // Reduce initial render amount
          maxToRenderPerBatch={1} // Reduce number in each render batch
          updateCellsBatchingPeriod={100} // Increase time between renders
          windowSize={3} // Reduce the window size
          scrollEnabled={false}
          data={item.album}
          renderItem={
            
            ({ item: albumItem, index }) =>
          {

            const IsAudio = (albumItem.MediaType === "audio" || albumItem.MediaType === 3);
            const IsVideo = (albumItem.MediaType === "video" || albumItem.MediaType === 2);
            const IsImage = (albumItem.MediaType === "image" || albumItem.MediaType === 1);
            return <View style={{ paddingBottom: 50 }}>
              {IsImage ?
               (
        <>
                    <ImageProfile
                    userSettingsState={userSettingsState}
                    uri={albumItem.URL}
                    imageStyle={styles.imageStyle}
                    editable={false}
                    downloadable={false}
                    isShowMessageStatus={false}
                    resizeMode={"contain"}
                    // resizeMode={responsiveStyles.isWeb ? "contain" : null}
                    isBackgroundColorBlack={true}
                  />
                  </>
      ) 
      : IsVideo  || IsAudio ? (
        <>
                    <VideoImage
                    userSettingsState={userSettingsState}
                    videoUri={albumItem.URL}
                    videoStyle={styles.videoStyle}
                    editable={false}
                    durationTime={albumItem.Duration}
                    // isShowBorder={IsAudio}
                    videoContainerStyle={
                      [
                        {
                          backgroundColor: null,
                          // paddingBottom: 20,
                        }, 
                      ]}
                    isAudio={IsAudio}
                    resizeMode={"contain"}
                    isThumbnail={true}
                    isBackgroundColorBlack={true}
                  />
  </>
      ) : null}
              </View>
          }
          
        }
          /> */}
          {!IsInvitation ? 
          <View style={{ paddingHorizontal: responsiveStyles.isDesktop ? 50 : null }}>
          <CommendComponent 
          userSettingsState={userSettingsState} 
          onCommentPress={() => openDialog(item)} 
          onLikePress={()=> LikePostHandler(item.Id)}
          isLike={item.IsUserLiked}
          showEditButton={showEditButton}
          onEditPress={onEditPress}
          IsDemo={IsDemo}
          />
          <Text style={[styles.likes]}> {item.Like} {i18n.t('media.ManageTributesComponent_Likes')}</Text>
          <TouchableOpacity style={styles.commentContainer} onPress={() => openDialog(item)}>
            <Text style={[styles.comment]}>{i18n.t('media.ManageTributesComponent_Comments')}</Text>
            <View style={styles.commentsNumberContainer}>
            <Text style={[styles.commentsNumber]}>{item.Comments.length}</Text>
            </View>
            </TouchableOpacity>
            </View>
            : 
            <View style={styles.buttonContainer}>
            <ButtonTypeButton
            userSettingsState={userSettingsState}
            style={styles.buttonAction}
            loading={isAcceptTributeLoading === item.Id}
            styleButton={styles.inviteStyleButton}
             onPress={() => {
              AcceptTributePostInvitationHandler(item.Id);
             }}
            >
              {i18n.t('media.ManageTributesComponent_Accept')}
        </ButtonTypeButton>
        <ButtonTypeButton
        userSettingsState={userSettingsState}
            style={styles.buttonAction}
            styleButton={styles.inviteStyleButton}
            loading={isDeclineTributeLoading === item.Id}
            onPress={() => {
              DeclineTributePostInvitationHandler(item.Id);
            }}
            >
              {i18n.t('media.ManageTributesComponent_Decline')}
        </ButtonTypeButton>
        </View>
        }
          </View>
      </View>
      }
      , [userSettingsState, responsiveStyles])
    }
      ListEmptyComponent={
        isMediaPost ? 
        <View style={{paddingTop: 5,}}>
        <MediaEmptyListComponent
        UserName={UserName} 
        isEditing={isEditing} 
        UserId={UserProfileId} 
        userSettingsState={userSettingsState}
        navigation={navigation}
        />
        </View>
        :
      <TributeEmptyListComponent 
      isEditing={isEditing} 
      UserId={UserProfileId} 
      userSettingsState={userSettingsState}
      navigation={navigation}
      />
        }
      />
  </View>

  );
}
const styling = (responsiveStyles) => StyleSheet.create({



  TributeContainer: {
    flex:1,
    // marginHorizontal: responsiveStyles.screenWidth * 0.05,
    padding: 15,
    borderColor: GlobalStyles.colors.buttonBorderColor,
    borderWidth: 1,
    width:
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 
    : responsiveStyles.Tablet ? responsiveStyles.screenWidth * 0.7
     : responsiveStyles.screenWidth * 0.9,

    marginVertical: 10,
    borderRadius: 10,
    alignSelf:'center',

  },
  bottomCard: {

  },

  userRowContainer: {
    flex: 1,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    alignItems:'center',


  },
  userPhoto: {
    width: 50,
    height: 50,
    borderRadius: 100,
    // borderWidth: 4,
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    marginRight: !responsiveStyles.isRTL ? 10 : null,
    marginLeft: responsiveStyles.isRTL ? 10 : null,

  },
  imageStyle: {
    width: '100%',
    aspectRatio: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 2 / 1 : 1,
    maxHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : 300,
    marginBottom: 10,
  },
  videoStyle: {
    width: '100%',
    aspectRatio: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 2 / 1 : 1,
    maxHeight: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : 300,
    marginBottom: 10,
  },
  userNameContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    width: (responsiveStyles.screenWidth * 0.9) - 100,
    // width: responsiveStyles.screenWidth * 0.7,
    alignItems:'center',
    // backgroundColor:'brown',
  },
  userName: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingHorizontal: 10,
  },
  createdDate: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  title: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingVertical: 10,
  },
  menuItem: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  description: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingBottom: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems:'center',
    paddingTop: 20,
    paddingBottom: 10,
  },
  button: {
    width: !responsiveStyles.isMobile || responsiveStyles.isWeb ? responsiveStyles.screenWidth * 0.2 : responsiveStyles.screenWidth * 0.9,
    minWidth: !responsiveStyles.isMobile || responsiveStyles.isWeb ? 150 : null,
  },
  buttonAction: {
    width: 
    responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.1 
    : responsiveStyles.isTablet ? responsiveStyles.screenWidth * 0.2
    : responsiveStyles.screenWidth * 0.4,
  },
  inviteStyleButton: {
    // backgroundColor: GlobalStyles.colors.Lavender,
  },
  likes: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingTop: 5,
    paddingHorizontal: 20,
    fontWeight: '700',
  },
  commentContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    paddingHorizontal: 20,
  },
  comment: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingTop: 5,
    paddingHorizontal: 5,
    },
    commentsNumberContainer: {
      backgroundColor: GlobalStyles.colors.ImageMediaModalButtonColor,
      // padding: 10,
      borderRadius: 100,
      width: 30,
      height: 30,
      justifyContent:'center',
      alignItems:'center'
    },
    commentsNumber: {
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
    },
    menuContainer:{
    
      backgroundColor:GlobalStyles[responsiveStyles.ThemeValue]?.SecondrayBackgroundColor, 
      borderWidth:1,
       borderColor:GlobalStyles[responsiveStyles.ThemeValue]?.inputBorderColor, 
       borderRadius: 10,
       
  
      
  },
  menuItemText:{
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    alignItems:'center',
  },
});

export default ManageTributesComponent;